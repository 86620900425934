import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../constants";
import { useContext } from "react";
import { TimesheetContext } from "../contexts/TimesheetContext";
import { HolidayModel } from "../models/HolidayModel";

const fetchHolidays = async (token: string): Promise<HolidayModel[]> => {
  const response = await fetch(`${baseURL}/api/Holiday/GetAll`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) throw new Error("Failed to fetch holidays data");
  return await response.json();
};

export const useGetHolidays = () => {
  const { accessToken: token } = useContext(TimesheetContext);
  return useQuery<HolidayModel[], Error>({
    queryKey: ["holidays"],
    queryFn: () => fetchHolidays(token),
    enabled: !!token,
  });
};
