import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { ParseDate } from "../../utils/dateUtils";


const exportManagersReport = async (begin: Date, end: Date, token: string) => {
  const url = `${baseURL}/api/Timesheet/Export/${ParseDate(begin)}/${ParseDate(end)}?forManager=true`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment; filename=report.xlsx",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to export report");
  }

  const blob = await response.blob();
  const objectURL = URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.href = objectURL;
  tempLink.setAttribute("download", `report/from/${ParseDate(begin)}/to/${ParseDate(end)}.xlsx`);
  tempLink.click();
};

// Custom hook using useMutation
export const useExportManagersReport = () => {
  return useMutation({
    mutationFn: ({ begin, end, token }: { begin: Date; end: Date; token: string }) =>
      exportManagersReport(begin, end, token),
  });
};
