import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToasts } from "@tag/tag-components-react-v4";
import {
    baseURL,
    TOAST_CONTAINER_ID,
    SUCCESS_DELETE_EMPLOYEE,
    ERROR_COULDNT_DELETE_EMPLOYEE,
} from "../../constants";
import { ParseDate } from "../../utils/dateUtils";

const deleteEmployeeAdmin = async (id: number, token: string, leaveDate: Date) => {
    const response = await fetch(`${baseURL}/api/Admin/Fire/${id}/${ParseDate(leaveDate)}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) throw new Error(`Failed to delete Employee. Status: ${response.status}`);
    return { statusCode: response.status };
};

export const useDeleteEmployeeAdmin = () => {
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);

    return useMutation({
        mutationKey: ["deleteEmployeeAdmin"],
        mutationFn: ({ id, token, leaveDate }: { id: number; token: string; leaveDate: Date }) =>
            deleteEmployeeAdmin(id, token, leaveDate),
        onSuccess: () => {
            queryClient.invalidateQueries();
            toast(SUCCESS_DELETE_EMPLOYEE, { toastType: "success" });
        },
        onError: () => {
            toast(ERROR_COULDNT_DELETE_EMPLOYEE, { toastType: "error" });
        },
    });
};
