import {
    DataList,
    design,
    H5,
    P,
    SimpleButton,
    Spinner,
    SquareButton,
} from '@tag/tag-components-react-v4';
import { DeleteFilled, EditFilled, PlusFilled } from '@tag/tag-icons';
import { useState } from 'react';
import { DayOffModal } from '../components/DayOffModal/DayOffModal';
import { getHolidayIcons } from '../utils/getIconsWithAccents';
import { DeleteDayOffModal } from '../components/DayOffModal/DeleteDayOffModal';
import { isEditOrDeleteDisabled, LeaveData, mapHolidayToLeaveData } from '../utils/leavesPageUtils';
import { useGetHolidays } from '../api/useGetHolidays';
import dayjs from 'dayjs';

export const LeavesPage = () => {
    const { data: holidays, isLoading, error } = useGetHolidays();
    const [activeModal, setActiveModal] = useState<'modal' | 'deleteModal' | null>(null);
    const [modalInitialValues, setModalInitialValues] = useState<LeaveData | null>(null);

    if (isLoading) return <Spinner />;
    if (error) return <P>Couldn't retrieve holidays</P>;

    const leavesData: LeaveData[] = holidays ? mapHolidayToLeaveData(holidays) : [];
    const onCancelLeaveModal = (): void => {
        setModalInitialValues(null);
        setActiveModal(null);
    };
    const onEditLeave = (dataItem: LeaveData): void => {
        setModalInitialValues(dataItem);
        setActiveModal('modal');
    };
    const onDeleteLeave = (dataItem: LeaveData): void => {
        setModalInitialValues(dataItem);
        setActiveModal('deleteModal');
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit',
            }}
        >
            <SimpleButton
                accent="teal"
                onClick={() => setActiveModal('modal')}
                startIcon={<PlusFilled />}
                style={{ alignSelf: 'flex-end', margin: '16px' }}
            >
                Book Leave
            </SimpleButton>
            <DataList
                renderIcon={(dataItem) =>
                    getHolidayIcons(dataItem.model.type ? dataItem.model.type : undefined)
                }
                primaryField="typeName"
                primaryFieldProps={{
                    style: { display: 'flex', maxWidth: '100%', flexWrap: 'nowrap' },
                }}
                renderRightElements={(dataItem) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <H5
                            style={{
                                fontWeight: 'normal',
                                color: design.surfaces.s050.color,
                            }}
                        >
                            {dayjs(dataItem.begin).format('DD/MM/YYYY')}
                        </H5>
                        <H5
                            style={{
                                fontWeight: 'normal',
                                color: design.surfaces.s050.color,
                            }}
                        >
                            {dayjs(dataItem.end).format('DD/MM/YYYY')}
                        </H5>
                        <SquareButton
                            onClick={() => onEditLeave(dataItem)}
                            icon={<EditFilled />}
                            disabled={isEditOrDeleteDisabled(dataItem)}
                        />
                        <SquareButton
                            onClick={() => onDeleteLeave(dataItem)}
                            icon={<DeleteFilled />}
                            disabled={isEditOrDeleteDisabled(dataItem)}
                        />
                    </div>
                )}
                data={leavesData}
            />

            {activeModal === 'modal' && (
                <DayOffModal initialValues={modalInitialValues} onCancel={onCancelLeaveModal} />
            )}
            {activeModal === 'deleteModal' && modalInitialValues && (
                <DeleteDayOffModal
                    holiday={modalInitialValues.model}
                    onClose={onCancelLeaveModal}
                />
            )}
        </div>
    );
};
