import {
    Alert,
    design,
    H5,
    Label,
    List,
    ListItem,
    ListItemElement,
    ListItemPrimaryElement,
    P,
    Pager,
    Pill,
    Pills,
    RoundButton,
    Searchbox,
    Spinner,
    SquareButton,
} from "@tag/tag-components-react-v4";
import { useContext, useState } from "react";
import { Divider } from "../commons/Divider";
import { EditFilled, ExitFilled, PlusFilled, UserFilled } from "@tag/tag-icons";
import { useGetEmployees } from "../../api/useGetEmployees";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { ProfileModel } from "../../models/ProfileModel";
import { EmployeeModal } from "./EmployeeModal";
import { DeleteEmployeeModal } from "./DeleteEmployeeModal";
import { useDebounce } from "../../hooks/useDebounce";
import { getEmployeePills } from "../../utils/employeesPageUtils";

export const EmployeesList = () => {
    const { accessToken } = useContext(TimesheetContext);
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const debouncedSearchCriteria = useDebounce(searchCriteria, 250);
    const [page, setPage] = useState<number>(1);
    const pageSize = 8;
    const [activeModal, setActiveModal] = useState<"modal" | "deleteModal" | null>(null);
    const [modalInitialValues, setModalInitialValues] = useState<ProfileModel | null>(null);

    const { data: employeesData, isLoading } = useGetEmployees(
        page,
        pageSize,
        debouncedSearchCriteria,
        accessToken,
    );

    const handleSearch = (e: any) => {
        setSearchCriteria(e.target.value);
        setPage(1);
    };
    const onCloseModal = (): void => {
        setModalInitialValues(null);
        setActiveModal(null);
    };
    const onEditEmployee = (dataItem: ProfileModel): void => {
        setModalInitialValues(dataItem);
        setActiveModal("modal");
    };
    const onDeleteEmployee = (dataItem: ProfileModel): void => {
        setModalInitialValues(dataItem);
        setActiveModal("deleteModal");
    };

    return (
        <div>
            <Divider content={"Employees"} orientation={"center"} />
            <div
                style={{
                    margin: "1%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                }}
            >
                <Searchbox
                    onChange={handleSearch}
                    clearButton
                    value={searchCriteria}
                    style={{ width: "100%" }}
                    placeholder="Search by Name, Email, Marca or Department"
                />
                <Pill
                    variant="outlined"
                    accent="teal"
                    size="large"
                    style={{
                        height: "32px",
                        fontSize: "16px",
                        display: "flex",
                        gap: "8px",
                    }}
                >
                    <Label>Records: </Label>
                    <P style={{ fontSize: "16px" }}>{employeesData?.totalCount}</P>
                </Pill>
                <RoundButton
                    accent="teal"
                    icon={<PlusFilled />}
                    onClick={() => setActiveModal("modal")}
                    style={{ width: "36px" }}
                />
            </div>
            {isLoading ? (
                <Spinner>Loading... </Spinner>
            ) : employeesData && employeesData.items.length ? (
                <div style={{ margin: "1% 1% 0 1%" }}>
                    <List>
                        {employeesData?.items?.map((employee, _index) => {
                            const pillsData = getEmployeePills(employee);
                            return (
                                <ListItem key={`employee-${employee.marca}`}>
                                    <ListItemElement icon={<UserFilled />}>
                                        <ListItemPrimaryElement>
                                            {employee.fullName}
                                        </ListItemPrimaryElement>
                                        {employee.email}
                                        {/* edit and delete button */}
                                    </ListItemElement>
                                    <div style={{ display: "flex", gap: "1rem" }}>
                                        <Pills pills={pillsData} size="large" />
                                        <SquareButton
                                            onClick={() => onEditEmployee(employee)}
                                            icon={<EditFilled />}
                                        />
                                        <SquareButton
                                            onClick={() => onDeleteEmployee(employee)}
                                            icon={<ExitFilled />}
                                        />
                                    </div>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Pager
                        page={page}
                        pageCount={Math.ceil(employeesData.totalCount / pageSize)}
                        style={{
                            display: "flex",
                            padding: "10px",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start",
                        }}
                        showFirstLast
                        onPageRequest={(e) => setPage(e.page)}
                    />
                </div>
            ) : (
                <Alert style={{ margin: "1%", width: "fit-content" }} type="warning">
                    No users found!
                </Alert>
            )}

            {activeModal === "modal" && (
                <EmployeeModal initialValues={modalInitialValues} onClose={onCloseModal} />
            )}
            {activeModal === "deleteModal" && modalInitialValues && (
                <DeleteEmployeeModal employee={modalInitialValues} onClose={onCloseModal} />
            )}
        </div>
    );
};
