import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { getMonday, ParseDate } from "../../utils/dateUtils";
import { ManagerStatsModel } from "../../models/ManagerStatsModel";
import { PaginationModel } from "../../models/PaginationModel";

const fetchValidationStatsForAdmin = async( 
	token: string, 
	begin: Date, 
	end: Date, 
	page: number, 
	pageSize: number ,
	searchCriteria: string, 
): Promise<PaginationModel<ManagerStatsModel>> => {
	const monday = ParseDate(getMonday(begin));
	const friday = ParseDate(end);
	const pageSkip = (page - 1) * pageSize;
	const url = `${baseURL}/api/Admin/GetValidationStats/${monday}/${friday}?take=${pageSize}&skip=${pageSkip}&searchCriteria=${searchCriteria}`;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json;charset=utf-8",
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.ok) {
		throw new Error("Failed to fetch stats");
	}

	return await response.json();
}

export const useStatsForAdmin = ( token: string, begin: Date, end: Date , page: number, pageSize:number, searchCriteria: string) => {
	return useQuery<PaginationModel<ManagerStatsModel>, Error>({
		queryKey: ["stats", begin, end, page, pageSize, searchCriteria],
		queryFn: () => fetchValidationStatsForAdmin(token, begin, end, page, pageSize, searchCriteria),
		enabled: !!token,
		retry: 1,
	});
}