import React from "react";
import { BadApplesModel } from "../../models/BadApplesModel";
import { Alert, Pager, Spinner } from "@tag/tag-components-react-v4";
import { BadApplesList } from "./BadApplesList";
import {
    IncompleteListModel,
    IncompleteTimesheetsModel,
} from "../../models/IncompleteTimesheetsModel";
export type ManagerBadApplesTabsProps = {
    isLastWeek: boolean;
    isLoading: boolean;
    error: Error | null;
    badApplesData: IncompleteListModel | undefined;
    page: number;
    setPage: (page: number) => void;
};
export const ManagerBadApplesTabs = (props: ManagerBadApplesTabsProps) => {
    const { isLastWeek, isLoading, error, badApplesData, page, setPage } = props;

    let content;
    if (isLoading) {
        content = <Spinner style={{ marginTop: "2%", height: "400px" }}>Loading ...</Spinner>;
    } else if (error) {
        content = (
            <Alert style={{ margin: "2%", width: "fit-content" }} type="error">
                Error fetching incomplete timesheets! Please <strong>refresh</strong> the page.
            </Alert>
        );
    } else if (badApplesData && badApplesData.items[0].length > 0) {
        content = (
            <div style={{ height: "400px" }}>
                <BadApplesList isLastWeek={isLastWeek} badApples={badApplesData.items[0]} />
                <Pager
                    page={page}
                    pageCount={Math.ceil(badApplesData.totalCount / 5)}
                    style={{
                        display: "flex",
                        padding: "10px",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "340px",
                    }}
                    onPageRequest={(e) => setPage(e.page)}
                />
            </div>
        );
    } else {
        content = (
            <Alert style={{ margin: "1%", width: "fit-content" }} type="success">
                No incomplete timesheets found! <strong>This is a very very happy case!</strong>
            </Alert>
        );
    }

    return <div>{content}</div>;
};
