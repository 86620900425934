import { useFormik } from "formik";
import { ProfileModel } from "../../models/ProfileModel";
import {
    ModalForm,
    TabContent,
    TabHeader,
    TabHeaders,
    Tabs,
    useToasts,
} from "@tag/tag-components-react-v4";
import { EmployeeForm } from "./EmployeeForm";
import {
    ERROR_COULDNT_ADDUPDATE_EMPLOYEE_DETAILS,
    ERROR_COULDNT_UPDATE_WORKHOURS,
    ERROR_COULDNT_UPDATE_WORKLOCATION,
    INFO_NO_CHANGES_DETECTED,
    SUCCESS_ADDUPDATE_EMPLOYEE_DETAILS,
    SUCCESS_UPDATE_WORKHOURS,
    SUCCESS_UPDATE_WORKLOCATION,
    TOAST_CONTAINER_ID,
} from "../../constants";
import _ from "lodash";
import { useAddUpdateEmployeeAdmin } from "../../api/EmployeesApi/useAddUpdateEmployeeAdmin";
import { useContext, useEffect } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import {
    prepareFormValuesForSubmit,
    setFormInitialValues,
    validate,
} from "../../utils/employeesPageUtils";
import { WorkHoursForm } from "./WorkHoursForm";
import { WorkLocationForm } from "./WorkLocationForm";
import { useUpdateWorkLocationAdmin } from "../../api/EmployeesApi/useUpdateWorkLocationAdmin";
import { useUpdateWorkHoursAdmin } from "../../api/EmployeesApi/useUpdateWorkHoursAdmin";
import { UpdateWorkHours } from "../../models/UpdateWorkHours";

export const EmployeeModal = ({
    initialValues,
    onClose,
}: {
    initialValues: ProfileModel | null;
    onClose: () => void;
}) => {
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const { mutateAsync: addUpdateEmployeeAsync } = useAddUpdateEmployeeAdmin();
    const { mutateAsync: updateWorkLocationAsync } = useUpdateWorkLocationAdmin();
    const { mutateAsync: updateWorkHoursAsync } = useUpdateWorkHoursAdmin();
    const formik = useFormik({
        initialValues: setFormInitialValues(initialValues),
        validate,
        onSubmit: async (values) => {
            if (_.isEqual(formik.initialValues, values)) {
                toast(INFO_NO_CHANGES_DETECTED, { toastType: "information" });
                onClose();
                return;
            }
            if (!_.isEqual(formik.initialValues.workLocationSchedule, values.workLocationSchedule))
                initialValues?.workLocationSchedule.forEach(async (workLocation, index) => {
                    const workLocationFormField = values.workLocationSchedule[index];
                    if (!workLocationFormField) return;
                    if (workLocationFormField.workType.id !== workLocation.workType.id)
                        try {
                            await updateWorkLocationAsync({
                                id: workLocation.id,
                                typeId: workLocationFormField.workType.id,
                                token: accessToken,
                            });
                            toast(SUCCESS_UPDATE_WORKLOCATION, { toastType: "success" });
                        } catch (error) {
                            toast(ERROR_COULDNT_UPDATE_WORKLOCATION, { toastType: "error" });
                        }
                });
            if (
                !_.isEqual(
                    _.pick(formik.initialValues, [
                        "startDate",
                        "workHours",
                        "scheduleBegin",
                        "scheduleEnd",
                    ]),
                    _.pick(values, ["startDate", "workHours", "scheduleBegin", "scheduleEnd"]),
                ) &&
                initialValues
            ) {
                const preparedWorkHours: UpdateWorkHours = {
                    from: values.startDate!,
                    value: values.workHours,
                    scheduleBegin: values.scheduleBegin,
                    scheduleEnd: values.scheduleEnd,
                };
                try {
                    await updateWorkHoursAsync({
                        id: initialValues.id,
                        workingHours: preparedWorkHours,
                        token: accessToken,
                    });
                    toast(SUCCESS_UPDATE_WORKHOURS, { toastType: "success" });
                } catch (error) {
                    toast(ERROR_COULDNT_UPDATE_WORKHOURS, { toastType: "error" });
                }
            }
            if (
                !_.isEqual(
                    _.omit(formik.initialValues, ["workLocationSchedule"]),
                    _.omit(values, ["workLocationSchedule"]),
                )
            ) {
                const preparedEmployee = prepareFormValuesForSubmit(values);
                try {
                    await addUpdateEmployeeAsync({
                        employee: preparedEmployee,
                        token: accessToken,
                        id: initialValues?.id ?? 0,
                    });
                    toast(SUCCESS_ADDUPDATE_EMPLOYEE_DETAILS, { toastType: "success" });
                } catch (error) {
                    toast(ERROR_COULDNT_ADDUPDATE_EMPLOYEE_DETAILS, { toastType: "error" });
                }
            }
            onClose();
        },
    });

    useEffect(() => {
        if (!formik.values.departmentId) {
            formik.setFieldValue("scheduleBegin", "--:--");
            formik.setFieldValue("scheduleEnd", "--:--");
        }
    }, [formik.values.departmentId]);
    return (
        <ModalForm
            visible={true}
            heading={`${initialValues ? "Edit" : "Create"} Employee`}
            submitButtonProps={{ text: "Save", style: { padding: "0px 32px" } }}
            cancelButtonProps={{ text: "Exit", style: { padding: "0px 32px" } }}
            onSubmit={formik.handleSubmit}
            onClose={onClose}
            headingProps={{
                style: { fontWeight: "bold" },
            }}
        >
            {initialValues ? (
                <Tabs
                    accent="teal"
                    defaultValue="tab1"
                    onChange={(name) => console.log("Tab", name)}
                >
                    <TabHeaders
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                        }}
                    >
                        <TabHeader value="tab1">General Information</TabHeader>
                        <TabHeader value="tab2">Work Hours</TabHeader>
                        <TabHeader value="tab3">WorkLocation</TabHeader>
                    </TabHeaders>
                    <TabContent value="tab1" style={{ paddingTop: "10px" }}>
                        <EmployeeForm formik={formik} />
                    </TabContent>
                    <TabContent value="tab2" style={{ paddingTop: "10px" }}>
                        <WorkHoursForm
                            formik={formik}
                            workHoursHistory={initialValues.workingHoursHistory}
                        />
                    </TabContent>
                    <TabContent value="tab3" style={{ paddingTop: "10px" }}>
                        <WorkLocationForm formik={formik} />
                    </TabContent>
                </Tabs>
            ) : (
                <EmployeeForm formik={formik} />
            )}
        </ModalForm>
    );
};
