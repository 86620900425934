import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { ProfileModel } from "../../models/ProfileModel";

const fetchProfile = async (token: string): Promise<ProfileModel> => {
  const response = await fetch(baseURL + "/api/Profile/Get", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (!response.ok) {
    throw new Error("Error fetching profile");
  }
  return await response.json();
};

export const useProfile = (token: string) => {
    return useQuery<ProfileModel, Error>({
        queryKey: ["profile", token], // Cache key, token is part of the key
        queryFn: () => fetchProfile(token), // Function to fetch the profile
        enabled: !!token, // Only run the query if token exists
    });
};
