import {
    List,
    ListItem,
    ListItemElement,
    P,
    Toggle,
    ToggleOption,
} from "@tag/tag-components-react-v4";
import EmployeeModalStyles from "./EmployeeModal.module.scss";
import { ClockFilled } from "@tag/tag-icons";
import { FormikProps } from "formik";
import { EmployeeFormFields } from "../../utils/employeesPageUtils";

export const WorkLocationForm = ({ formik }: { formik: FormikProps<EmployeeFormFields> }) => {
    return (
        <div className={EmployeeModalStyles.dateContainer}>
            <List className={EmployeeModalStyles.fullWidthColumn}>
                {formik.values.workLocationSchedule?.map((workLocation, index) => (
                    <ListItem
                        key={workLocation.dayOfWeek}
                        style={{ display: "flex", justifyContent: "space-between", gap: "64px" }}
                    >
                        <ListItemElement icon={<ClockFilled />}>
                            <P>{workLocation.dayOfWeek}</P>
                        </ListItemElement>
                        <ListItemElement>
                            <Toggle
                                {...formik.getFieldProps(
                                    `workLocationSchedule[${index}].workType.id`,
                                )}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        `workLocationSchedule[${index}].workType.id`,
                                        e.value,
                                    );
                                }}
                            >
                                <ToggleOption value={1}>Office</ToggleOption>
                                <ToggleOption value={2}>Home Office</ToggleOption>
                            </Toggle>
                        </ListItemElement>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};
