import React, { useState } from 'react';
import { ValidationManyModel } from '../../models/ValidationManyModel';
import { getStatsColumns, getStyles, getValidationStatsPage } from '../../utils/managerUtils';
import {
    Pager,
    Table,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableHead,
    TableHeadCell,
    TableHeadRow,
    Tooltip,
} from '@tag/tag-components-react-v4';
import { ManagerStatsModel } from '../../models/ManagerStatsModel';
import { TimesheetModel } from '../../models/TimesheetModel';

export type ManagerTeamStatsTableProps = {
    selected: ValidationManyModel[];
    setSelected: React.Dispatch<React.SetStateAction<ValidationManyModel[]>>;
    validationStatsWithUiKey: ManagerStatsModel[];
    startDate: Date;
    endDate: Date;
};
export const ManagerTeamStatsTable = (props: ManagerTeamStatsTableProps) => {
    const { selected, setSelected, validationStatsWithUiKey, startDate, endDate } = props;
    const [page, setPage] = useState<number>(1);
    const pageSize = 5;
    const pageCount =
        validationStatsWithUiKey.length && Math.ceil(validationStatsWithUiKey.length / pageSize);
    const validationStatsPage = getValidationStatsPage(validationStatsWithUiKey, page, pageSize);
    const columns = getStatsColumns(startDate, endDate);

    const handleButtonClick = (day: any, employeeId: number) => {
        const { date, uiKey, id, dayBegin, dayEnd, holidayType } = day;

        setSelected((prevState: ValidationManyModel[]): ValidationManyModel[] => {
            const selectedIndex = prevState.findIndex(
                (item: ValidationManyModel) => item.id === uiKey,
            );

            if (selectedIndex === -1) {
                // Add to selected if not already present
                return [
                    ...prevState,
                    {
                        id: uiKey,
                        ownedBy: employeeId,
                        date,
                        statusCode: '',
                        dayBegin,
                        dayEnd,
                        idPontaj: id,
                        holidayType,
                    },
                ];
            } else {
                // Remove from selected if present
                return prevState.filter((_, index) => index !== selectedIndex);
            }
        });
    };
    const uiSelect = selected.map(function (e) {
        return e.id;
    });

    return (
        <div style={{ margin: '1%' }}>
            <div style={{ height: '250px' }}>
                <Table style={{ width: '75rem' }}>
                    <TableHead>
                        <TableHeadRow>
                            {columns.map((column, index) => (
                                <TableHeadCell
                                    key={column}
                                    style={{
                                        width: '50px',
                                        textAlign: 'center',
                                        borderRight: '1px solid #e0e0e0',
                                    }}
                                >
                                    {column}
                                </TableHeadCell>
                            ))}
                        </TableHeadRow>
                    </TableHead>
                    <TableBody>
                        {validationStatsPage.map((validationStat, index) => (
                            <TableBodyRow
                                key={`${validationStat.employee.id}-${validationStat.employee.marca}`}
                            >
                                <TableBodyCell
                                    style={{
                                        width: '50px',
                                        textAlign: 'center',
                                        borderRight: '1px solid #e0e0e0',
                                    }}
                                >
                                    {validationStat.employee.fullName}
                                </TableBodyCell>
                                {validationStat.days.map((day: any) => (
                                    <TableBodyCell
                                        key={`${validationStat.employee.id}-${day.date}`}
                                        style={{
                                            textAlign: 'center',
                                            borderRight: '1px solid #e0e0e0',
                                            ...getStyles(day, uiSelect),
                                        }}
                                        onClick={() =>
                                            handleButtonClick(day, validationStat.employee.id)
                                        }
                                    >
                                        {CustomCell(day)}
                                    </TableBodyCell>
                                ))}
                            </TableBodyRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <Pager
                page={page}
                pageCount={pageCount}
                accent="teal"
                showFirstLast
                onPageRequest={(e) => setPage(e.page)}
                style={{ marginTop: '1%' }}
            />
        </div>
    );
};

const CustomCell = (data: TimesheetModel) => {
    if (data.id === null && data.holidayType === null) {
        return <span>-</span>;
    }

    if (data.holidayType?.shortName) {
        return <span>{data.holidayType.shortName}</span>;
    }

    if (data.timesheetValidation !== null && data.dayBegin && data.dayEnd) {
        return (
            <Tooltip
                tooltipFor={
                    <span>
                        {data.dayBegin.slice(0, -3)} - {data.dayEnd.slice(0, -3)}
                    </span>
                }
            >
                <div style={{ textAlign: 'center' }}>
                    <strong>{`${data.timesheetValidation.statusLiteral} `}</strong>
                    <span>
                        by <strong>{`${data.timesheetValidation.validatedBy} `}</strong>
                    </span>
                    <span>
                        with credentials{' '}
                        <strong>{`${data.timesheetValidation.withCredentials}`}</strong>
                    </span>
                </div>
            </Tooltip>
        );
    }

    return <div>-</div>;
};
