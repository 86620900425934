import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants"; // Adjust path if necessary
import { ValidationManyModel } from "../../models/ValidationManyModel";

const managerValidateMany = async (
	days: ValidationManyModel[],
	token: string
): Promise<{ data: string; status: number }> => {
	const body = [...days];
	const response = await fetch(`${baseURL}/api/Manager/ValidateMany`, {
		method: "POST",
		headers: {
			"Content-Type": "text/json",
			Accept: "text/json",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify(body),
	});
	// const res = await response;
	if (response.status !== 200) {
		throw new Error(`Validation failed with status: ${response.status}`);
	} else {
		return { data: response.statusText, status: response.status };
	}
};

export const useManagerValidateMany = () => {
	return useMutation({
		mutationFn: ({ days, token }: { days: ValidationManyModel[]; token: string }) =>
			managerValidateMany(days, token),
	});
};
