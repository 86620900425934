import { Alert } from "@tag/tag-components-react-v4";
import { error } from "console";
import React from "react";
type ErrorCardProps = {
	error: Error | null;
};
export const ErrorCard = (props: ErrorCardProps) => {
	const { error } = props;
	return (
		<Alert
			style={{ margin: "2%", width: "fit-content" }}
			type="error"
		>
			{error ? error.message : "Something went wrong"}
		</Alert>
	);
};
