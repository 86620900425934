import { Card, DateRangePicker, H4, SimpleButton, useToasts } from "@tag/tag-components-react-v4";
import { useContext, useState } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { useExportManagersReport } from "../../api/ManagersApi/useExportManagersReport";
import { TOAST_CONTAINER_ID } from "../../constants";
import { CircleTickFilled, HourglassEmptyLined } from "@tag/tag-icons";
type RangeDateType = [Date, Date];
export const ManagerReportGenerator = () => {
	const { accessToken } = useContext(TimesheetContext);
	const { toast } = useToasts(TOAST_CONTAINER_ID);

	const [rangeDate, setRangeDate] = useState<RangeDateType>([new Date(), new Date()]);
	const { mutate: exportManagersReport, isPending: isExportLoading } = useExportManagersReport();

	const handleGenerateReport = () => {
		if (!rangeDate[0] || !rangeDate[1] || !accessToken) {
			toast("Please select a valid date range.", { toastType: "warning" });
			return;
		}

		toast("Your download will start in a few seconds...", { toastType: "information" });

		exportManagersReport(
			{ begin: rangeDate[0], end: rangeDate[1], token: accessToken },
			{
				onError: (error) => {
					toast(`Failed to generate report: ${error.message}`, { toastType: "error" });
				},
				onSuccess: () => {
					toast("Report generated successfully!", { toastType: "success" });
				},
			}
		);
	};
	return (
		<Card
			style={{
				textAlign: "center",
				padding: "1%",
				margin: "1%",
				boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 0px 1px",
				gap: "16px",
				display: "flex",
				justifyContent: "flex-start",
				alignItems: "center",
			}}
		>
			<H4> Number of Incomplete Timesheets </H4>

			<DateRangePicker
				pickerSize="default"
				onChange={(e) => {
					setRangeDate(e.value as RangeDateType);
				}}
				value={rangeDate}
			/>
			<SimpleButton
				disabled={!rangeDate[0] || !rangeDate[1] || isExportLoading}
				accent="teal"
				type="submit"
				startIcon={isExportLoading ? <HourglassEmptyLined /> : <CircleTickFilled />}
				onClick={handleGenerateReport}
			>
				{isExportLoading ? "Exporting..." : "Generate Report"}
			</SimpleButton>
		</Card>
	);
};
