import { createContext, PropsWithChildren, useState } from "react";
import { ProfileModel } from "../models/ProfileModel";
export interface ITimesheetContextValue {
	isProfileLoading: boolean;
	setIsProfileLoading: (isProfileLoading: boolean) => void;
	profile: ProfileModel | null;
	setProfile: (profile: ProfileModel) => void;
	accessToken: string;
	setAccessToken: (accessToken: string) => void;
}

export const TimesheetContext = createContext<ITimesheetContextValue>({} as any);

export const TimesheetContextProvider = ({ children }: PropsWithChildren<unknown>) => {
	const [isProfileLoading, setIsProfileLoading] = useState<boolean>(true);
	const [profile, setProfile] = useState<ProfileModel | null>(null);
	const [accessToken, setAccessToken] = useState<string>("");
	return (
		<TimesheetContext.Provider
			value={{
				isProfileLoading,
				setIsProfileLoading,
				profile,
				setProfile,
				accessToken,
				setAccessToken,
			}}
		>
			{children}
		</TimesheetContext.Provider>
	);
};
