import { useState } from "react";
import { useGetLogs } from "../api/useGetLogs";
import {
    Searchbox,
    Pill,
    Label,
    P,
    Spinner,
    DataList,
    Pager,
    H5,
    design,
} from "@tag/tag-components-react-v4";
import { ErrorCard } from "../components/commons/ErrorCard";
import { filterLogs, getLogsPages, sortLogs } from "../components/Developer/developerPageUtils";
import { CircleInfoFilled } from "@tag/tag-icons";
import dayjs from "dayjs";

export const DeveloperPage = () => {
    const { data: logs, isLoading, error } = useGetLogs();
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const pageSize = 7;

    if (isLoading) return <Spinner>Loading... </Spinner>;
    if (error) return <ErrorCard error={error} />;

    const handleSearch = (e: any) => {
        setSearchCriteria(e.target.value);
        setPage(1);
    };
    const sortedLogs = logs?.length ? sortLogs(logs) : [];
    const filteredLogs = filterLogs(sortedLogs, searchCriteria);
    const logsCount = filteredLogs?.length;
    const logsPageCount = logsCount && Math.ceil(logsCount / pageSize);
    const logsPageData = getLogsPages(filteredLogs, page, pageSize);

    return (
        <div style={{ height: "25rem" }}>
            <div style={{ margin: "2%", display: "flex", alignItems: "center", gap: "16px" }}>
                <Searchbox
                    placeholder="Search by Name, Email or Marca..."
                    onChange={handleSearch}
                    clearButton
                    value={searchCriteria}
                    style={{ width: "300px" }}
                />
                <Pill
                    variant="outlined"
                    accent="teal"
                    size="large"
                    style={{
                        height: "35px",
                        display: "flex",
                        fontSize: "15px",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <Label>Records: </Label>
                    <P style={{ fontSize: "15px" }}>{logsCount}</P>
                </Pill>
            </div>
            <DataList
                style={{ height: "80vh" }}
                data={logsPageData}
                renderIcon={() => <CircleInfoFilled accent="teal" size="40px" />}
                renderPrimaryElement={(dataItem) => (
                    <div style={{ height: "5rem" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                gap: "16px",
                                alignContent: "center",
                            }}
                        >
                            <H5>{dataItem.doneBy}</H5>
                            <P
                                style={{
                                    fontWeight: "normal",
                                    color: design.surfaces.s050.color,
                                }}
                            >
                                {dataItem.level}: {dataItem.action}
                            </P>
                            <Pill accent="grey">
                                {dayjs(dataItem.doneAt).format("DD/MM/YYYY, HH:mm")}
                            </Pill>
                        </div>
                        <P>{dataItem.details}</P>
                    </div>
                )}
            />
            <Pager
                page={page}
                pageCount={logsPageCount}
                accent="teal"
                showFirstLast
                onPageRequest={(e) => setPage(e.page)}
                style={{ margin: "8px" }}
            />
        </div>
    );
};
