import { useContext, useState } from 'react';
import { TimesheetNav } from '../components/commons/TimesheetNav/TimesheetNav';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { TimesheetWeek } from '../components/Timesheet/TimesheetWeek/TimesheetWeek';
import { useTimesheetWeek } from '../api/TimesheetApi/useWeek';
import { TimesheetContext } from '../contexts/TimesheetContext';
import { Spinner } from '@tag/tag-components-react-v4';
import { getMonday } from '../utils/dateUtils';
dayjs.extend(LocalizedFormat);
export interface TimesheetPageProps {
    // if needed
}

export const TimesheetPage = (props: TimesheetPageProps) => {
    const {} = props;
    const { accessToken } = useContext(TimesheetContext);
    const [startDate, setStartDate] = useState<Date>(
        getMonday(new Date()), // Monday
    );
    const [endDate, setEndDate] = useState<Date>(
        dayjs(startDate).startOf('week').add(5, 'day').toDate(), // Friday
    );
    const { data: WeekData, isLoading: isWeekLoading } = useTimesheetWeek(startDate, endDate);
    const handlePrevOrNextWeek = (direction: 'prev' | 'next') => {
        const offset = direction === 'prev' ? -7 : 7;
        setStartDate(dayjs(startDate).add(offset, 'day').toDate());
        setEndDate(dayjs(endDate).add(offset, 'day').toDate());
    };
    const handleCurrentWeek = () => {
        setStartDate(dayjs().startOf('week').add(1, 'day').toDate()); // Monday
        setEndDate(dayjs().startOf('week').add(5, 'day').toDate()); // Friday
    };

    return (
        <div style={{ margin: '1%' }}>
            <TimesheetNav
                startDate={startDate}
                endDate={endDate}
                handlePrevOrNextWeek={handlePrevOrNextWeek}
                handleCurrentWeek={handleCurrentWeek}
            />
            {isWeekLoading ? <Spinner>Loading... </Spinner> : <TimesheetWeek week={WeekData} />}
        </div>
    );
};
