import React from "react";
import { TimesheetPage } from "./TimesheetPage";
import { ManagersPage } from "./ManagersPage";
import { LeavesPage } from "./LeavesPage";
import { ProfilePage } from "./ProfilePage";
import { Navigate, Route, Routes } from "react-router-dom";
import { WorkInProgressCard } from "../components/commons/WorkInProgressCard";
import { DeveloperPage } from "./DeveloperPage";
import { EmployeesPage } from "./EmployeesPage";
import { TimesheetIOPage } from "./TimesheetIOPage";

export const Pages = () => {
    return (
        <Routes>
            <Route path="/timesheet" element={<TimesheetPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/leaves" element={<LeavesPage />} />
            <Route path="/managers" element={<ManagersPage />} />
            <Route path="/employees" element={<EmployeesPage />} />
            <Route path="/IOReport" element={<TimesheetIOPage />} />
            <Route path="/RDReport" element={<WorkInProgressCard />} />
            <Route path="/statistics" element={<WorkInProgressCard />} />
            <Route path="/logs" element={<DeveloperPage />} />

            <Route path="/" element={<Navigate to="/profile" replace />} />
            <Route path="*" element={<Navigate to="/profile" replace />} />
        </Routes>
    );
};
