import { useMutation, useQueryClient } from "@tanstack/react-query";
import { baseURL, ERROR_COULDNT_UPDATE_WORKLOCATION } from "../../constants";
import { UpdateWorkHours } from "../../models/UpdateWorkHours";
const updateWorkWorkHoursAdmin = async (
    id: number,
    workingHours: UpdateWorkHours,
    token: string,
): Promise<any> => {
    const response = await fetch(`${baseURL}/api/Admin/UpdateWorkingHours/${id}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "text/json",
            Accept: "text/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(workingHours),
    });

    if (!response.ok) throw new Error(ERROR_COULDNT_UPDATE_WORKLOCATION);
    return response.status;
};

export const useUpdateWorkHoursAdmin = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["updateWorkWorkHoursAdmin"],
        mutationFn: ({
            id,
            workingHours,
            token,
        }: {
            id: number;
            workingHours: UpdateWorkHours;
            token: string;
        }) => updateWorkWorkHoursAdmin(id, workingHours, token),
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
};
