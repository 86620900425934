import { useMutation, useQueryClient } from "@tanstack/react-query";
import { baseURL, SUCCESS_DELETED_DAY_OFF, TOAST_CONTAINER_ID } from "../constants";
import { useToasts } from "@tag/tag-components-react-v4";

const deleteHoliday = async (id: string, token: string) => {
    const response = await fetch(`${baseURL}/api/Holiday/Delete?id=${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) throw new Error(`Failed to delete holiday. Status: ${response.status}`);
    return { statusCode: response.status };
};

export const useDeleteHoliday = () => {
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);

    return useMutation({
        mutationKey: ["deleteHoliday"],
        mutationFn: ({ id, token }: { id: string; token: string }) => deleteHoliday(id, token),
        onSuccess: () => {
            queryClient.invalidateQueries();
            toast(SUCCESS_DELETED_DAY_OFF, { toastType: "success" });
        },
    });
};
