import React from "react";
import { AdminEmployeeStats } from "../components/Admin/AdminEmployeeStats";
import { AdminReportGenerator } from "../components/Admin/AdminReportGenerator";

export const TimesheetIOPage = () => {
    return (
        <div>
            {/* <AdminReportGenerator /> */}
            <AdminEmployeeStats />
        </div>
    );
};
