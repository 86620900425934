import React, { useEffect, useMemo } from "react";
import {
    Autocomplete,
    Badge,
    Card,
    H4,
    Label,
    Modal,
    SimpleButton,
    Spinner,
    SquareButton,
    Tooltip,
    useToasts,
} from "@tag/tag-components-react-v4";
import { useContext, useState } from "react";
import { TimesheetNav } from "../commons/TimesheetNav/TimesheetNav";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { TimesheetContext } from "../../contexts/TimesheetContext";
import { CircleCrossFilled, CircleTickFilled, SaveDiskFilled, UndoFilled } from "@tag/tag-icons";
import { ValidationManyModel } from "../../models/ValidationManyModel";
import { getMonday, makeTimes } from "../../utils/dateUtils";
import { getStatsForManagerWithUiKey, holidayOrNot } from "../../utils/managerUtils";
import { ManagerTeamStatsTable } from "./ManagerTeamStatsTable";
import { useValidationStatsForManager } from "../../api/ManagersApi/useValidationStatsForManager";
import { ErrorCard } from "../commons/ErrorCard";
import { useManagerValidateAll } from "../../api/ManagersApi/useManagerValidateAll";
import { useManagerValidateMany } from "../../api/ManagersApi/useManagerValidateMany";
import { TOAST_CONTAINER_ID } from "../../constants";
import { useQueryClient } from "@tanstack/react-query";
import { useManagerUpdateTimesheet } from "../../api/ManagersApi/useManagerUpdateTimesheet";

dayjs.extend(advancedFormat);

export type ManagerTeamStastProps = {};

export const ManagerTeamStats = (props: ManagerTeamStastProps) => {
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const queryClient = useQueryClient();

    const [startDate, setStartDate] = useState<Date>(
        getMonday(dayjs().toDate()), // Monday
    );
    const [endDate, setEndDate] = useState<Date>(
        dayjs(startDate).startOf("week").add(5, "day").toDate(), // Friday
    );
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [comboValue, setComboValue] = useState<string>("");
    const [selected, setSelected] = useState<ValidationManyModel[]>([]);

    const [dayState, setDayState] = useState<any>({
        id: null,
        date: null,
        dayBegin: null,
        dayEnd: null,
        workTypeId: null,
        profileId: 0,
    });
    useEffect(() => {
        if (selected.length === 1) {
            const { date, dayBegin, dayEnd, idPontaj, ownedBy } = selected[0];
            if (dayBegin !== null && dayEnd !== null) {
                setDayState((prev: any) => ({
                    ...prev,
                    // ...dayState,
                    profileId: ownedBy,
                    date: new Date(date).toDateString(),
                    dayBegin: dayBegin,
                    dayEnd: dayEnd,
                    id: idPontaj,
                    workTypeId: null,
                }));
            } else {
                setDayState((prev: any) => ({
                    ...prev,
                    // ...dayState,
                    profileId: ownedBy,
                    date: new Date(date).toDateString(),
                    dayBegin: null,
                    dayEnd: null,
                    id: null,
                    workTypeId: null,
                }));
            }
        } else {
            setDayState((prev: any) => ({
                ...prev,
                // ...dayState,
                profileId: 0,
                date: "",
                dayBegin: undefined,
                dayEnd: undefined,
                id: null,
                workTypeId: null,
            }));
        }
    }, [selected]);

    const handlePrevOrNextWeek = (direction: "prev" | "next") => {
        const offset = direction === "prev" ? -7 : 7;
        setStartDate(dayjs(startDate).add(offset, "day").toDate());
        setEndDate(dayjs(endDate).add(offset, "day").toDate());
    };
    const handleCurrentWeek = () => {
        setStartDate(dayjs().startOf("week").add(1, "day").toDate()); // Monday
        setEndDate(dayjs().startOf("week").add(5, "day").toDate()); // Friday
    };
    const TimeDates = makeTimes();

    const {
        data: validationStatsForManager,
        isLoading: isValidationStatsLoading,
        error: validationStatsError,
    } = useValidationStatsForManager(accessToken, startDate, endDate);
    const validationStatsWithUiKey = validationStatsForManager
        ? getStatsForManagerWithUiKey(validationStatsForManager)
        : [];

    const holidayOrNotArray = useMemo(() => holidayOrNot(selected), [selected]);

    const { mutate: validateAll } = useManagerValidateAll();
    const handleValidateAll = (status: number) => {
        validateAll(
            {
                begin: startDate,
                end: endDate, // Provide the begin date here
                statusCode: status, // Example status code
                token: accessToken,
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries();
                },
            },
        );
    };

    const { mutate: validateMany } = useManagerValidateMany();
    const handleValidateMany = (status: string) => {
        if (!comboValue) return;
        const toValidate = selected.map((obj) => ({ ...obj, statusCode: status }));
        validateMany(
            { days: toValidate, token: accessToken },
            {
                onError: (error) =>
                    toast(`Failed to validate the timesheet: ${error.message}`, {
                        toastType: "error",
                    }),
                onSuccess: () => {
                    toast("Timesheet validated successfully!", { toastType: "success" });
                    queryClient.invalidateQueries();
                    setSelected([]);
                    setComboValue("");
                },
            },
        );
    };

    const { mutate: updateTimesheet } = useManagerUpdateTimesheet();
    const handleUpdate = () => {
        updateTimesheet(
            { id: dayState.profileId, day: dayState, token: accessToken },
            {
                onError: (error) =>
                    toast(`Failed to update the timesheet: ${error.message}`, {
                        toastType: "error",
                    }),
                onSuccess: () => {
                    toast("Timesheet updated successfully!", { toastType: "success" });
                    queryClient.invalidateQueries();
                    setSelected([]);
                },
            },
        );
    };
    return (
        <div>
            <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                <H4> Team Stats </H4>
            </Card>
            <div>
                <div style={{ margin: "1%", display: "flex", gap: "16px", alignItems: "center" }}>
                    <TimesheetNav
                        startDate={startDate}
                        endDate={endDate}
                        handlePrevOrNextWeek={handlePrevOrNextWeek}
                        handleCurrentWeek={handleCurrentWeek}
                    />
                    <Tooltip
                        tooltipFor={
                            <SquareButton
                                icon={<CircleTickFilled />}
                                accent="green"
                                onClick={() => setOpenApproveModal(true)}
                            />
                        }
                    >
                        Approve All Week
                    </Tooltip>
                    <Tooltip
                        tooltipFor={
                            <SquareButton
                                icon={<CircleCrossFilled />}
                                accent="destructive"
                                onClick={() => setOpenRejectModal(true)}
                            />
                        }
                    >
                        Reject All Week
                    </Tooltip>
                    <Autocomplete
                        disabled={selected.length === 0}
                        clearButton
                        style={{ width: "150px" }}
                        value={comboValue || ""}
                        placeholder="Select validation"
                        textField="name"
                        valueField="id"
                        data={[
                            { id: 1, name: "Approve" },
                            { id: 2, name: "Reject" },
                        ]}
                        onChange={(e) => setComboValue(e.target.value)}
                    />
                    <Tooltip
                        tooltipFor={
                            <SquareButton
                                icon={<UndoFilled />}
                                onClick={() => {
                                    setSelected([]);
                                }}
                            />
                        }
                    >
                        Clear selected
                    </Tooltip>
                    <Badge
                        style={{
                            width: "35px",
                            height: "35px",
                            backgroundColor: `${selected.length > 0 ? "teal" : "sgrey"}`,
                        }}
                    >
                        <Label style={{ color: "white" }}>{selected.length}</Label>
                    </Badge>

                    <SimpleButton
                        endIcon={<SaveDiskFilled />}
                        onClick={() => {
                            handleValidateMany(comboValue);
                        }}
                        accent="teal"
                        disabled={selected.length === 0 || comboValue === null || comboValue === ""}
                    >
                        Save
                    </SimpleButton>
                    <div style={{ marginLeft: "1%", display: "flex", gap: "8px" }}>
                        <Autocomplete
                            placeholder="Start time"
                            clearButton
                            style={{ width: "150px" }}
                            data={TimeDates}
                            textField="time"
                            valueField="time"
                            disabled={
                                selected.length === 0 ||
                                selected.length > 1 ||
                                holidayOrNotArray.length > 0
                            }
                            value={dayState.dayBegin || ""}
                            onChange={(e) => {
                                setDayState({ ...dayState, dayBegin: e.target.value });
                            }}
                        />
                        <Autocomplete
                            placeholder="End time"
                            clearButton
                            style={{ width: "150px" }}
                            data={TimeDates}
                            textField="time"
                            valueField="time"
                            disabled={
                                selected.length === 0 ||
                                selected.length > 1 ||
                                holidayOrNotArray.length > 0
                            }
                            value={dayState.dayEnd || ""}
                            onChange={(e) => {
                                setDayState({ ...dayState, dayEnd: e.target.value });
                            }}
                        />
                        <SimpleButton
                            endIcon={<SaveDiskFilled />}
                            onClick={() => {
                                handleUpdate();
                            }}
                            accent="teal"
                            disabled={
                                selected.length === 0 ||
                                selected.length > 1 ||
                                dayState.dayBegin === null ||
                                dayState.dayEnd === null ||
                                holidayOrNotArray.length > 0
                            }
                        >
                            Save
                        </SimpleButton>
                    </div>
                </div>
                {(() => {
                    if (isValidationStatsLoading) {
                        return <Spinner style={{ margin: "7%" }}>Loading...</Spinner>;
                    } else if (validationStatsError) {
                        return <ErrorCard error={validationStatsError} />;
                    } else {
                        return (
                            <ManagerTeamStatsTable
                                selected={selected}
                                setSelected={setSelected}
                                startDate={startDate}
                                endDate={endDate}
                                validationStatsWithUiKey={validationStatsWithUiKey}
                            />
                        );
                    }
                })()}
            </div>

            <Modal
                visible={openApproveModal}
                suppressCloseButton
                accent="green"
                heading="Are you sure you want to APPROVE All Week?"
                onClose={() => setOpenApproveModal(false)}
                primaryButtonProps={{
                    text: "Approve",
                    icon: <CircleTickFilled accent="white" />,
                }}
                onPrimaryButtonClick={() => handleValidateAll(1)}
            />
            <Modal
                suppressCloseButton
                visible={openRejectModal}
                heading="Are you sure you want to REJECT All Week?"
                onClose={() => setOpenRejectModal(false)}
                primaryButtonProps={{
                    text: "Reject",
                    icon: <CircleCrossFilled accent="white" />,
                }}
                onPrimaryButtonClick={() => handleValidateAll(2)}
            />
        </div>
    );
};
