import dayjs from "dayjs";
import { REQUIRED_VALIDATION_MESSAGE } from "../constants";
import { HolidayModel } from "../models/HolidayModel";

interface DayOffFormFields {
    typeId: number | null;
    begin: Date | null;
    end: Date | null;
}
export interface LeaveData {
    accent: any;
    begin: Date | null;
    end: Date | null;
    icon: string;
    typeName: string;
    model: HolidayModel;
}

export const mapHolidayToLeaveData = (holidays: HolidayModel[]): LeaveData[] =>
    holidays.map((obj) => ({
        begin: obj.begin,
        end: obj.end,
        typeName: obj.type ? obj.type.shortName + " - " + obj.type.name : "",
        accent: obj.type ? obj.type.accent : "",
        icon: obj.type ? obj.type.icon : "",
        model: obj,
    }));

export const isEditOrDeleteDisabled = (dataItem: LeaveData): boolean => {
  const isPastMonth = dayjs(dataItem.begin).isBefore(dayjs().startOf("month"));
  const isRoleRestricted = 
    dataItem.model.type?.requiredRole !== null && dataItem.model.type?.requiredRole !== "Admin";

  // Enable if requiredRole is null; otherwise, disable based on conditions.
  return isPastMonth || isRoleRestricted;
};



export const setModalInitialValues = (values: LeaveData | null): DayOffFormFields => {
    if (values)
        return {
            typeId: values.model.typeId,
            begin: values.begin && dayjs(values.begin).hour(0).toDate(),
            end: values.end && dayjs(values.end).hour(0).toDate(),
        };
    return { typeId: null, begin: null, end: null };
};

export const validate = (
    values: DayOffFormFields,
): Partial<Record<keyof DayOffFormFields, string>> => {
    const errors: Partial<Record<keyof DayOffFormFields, string>> = {};
    if (!values.begin) errors.begin = REQUIRED_VALIDATION_MESSAGE;
    if (!values.end) errors.end = REQUIRED_VALIDATION_MESSAGE;
    if (!values.typeId) errors.typeId = REQUIRED_VALIDATION_MESSAGE;
    return errors;
};

export const prepareFormValuesForSubmit = (
    values: DayOffFormFields,
    initialValues: LeaveData | null,
): HolidayModel => {
    if (initialValues)
        return {
            ...initialValues.model,
            begin: dayjs(values.begin).add(2, "hour").toDate(),
            end: dayjs(values.end).add(2, "hour").toDate(),
            typeId: values.typeId,
        };
    return {
        begin: values.begin,
        end: values.end,
        typeId: values.typeId,
        id: null,
        type: null,
    };
};
