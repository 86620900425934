import React, { useContext } from "react";
import { TimesheetModel } from "../../../models/TimesheetModel";
import { TimePicker } from "@tag/tag-components-react-v4";
import dayjs from "dayjs";
import { TimesheetContext } from "../../../contexts/TimesheetContext";
export type TimesheetRangePickerProps = {
    weekData: TimesheetModel;
    dayBegin: string | undefined;
    setDayBegin: (dayBegin: string | undefined) => void;
    dayEnd: string | undefined;
    setDayEnd: (dayEnd: string | undefined) => void;
};
export const TimesheetRangePicker = (props: TimesheetRangePickerProps) => {
    const { profile } = useContext(TimesheetContext);
    const { weekData, dayBegin, setDayBegin, dayEnd, setDayEnd } = props;
    // Convert time string (e.g., "08:00:00") to hour as number
    const extractHour = (time: string): number => parseInt(time.split(":")[0], 10);

    // Calculate min and max hours based on hours worked
    const minHour = profile?.department?.shiftBegin
        ? extractHour(profile?.department?.shiftBegin)
        : extractHour(profile?.scheduleBegin ?? "01:00:00");

    const maxHour = profile?.department?.shiftEnd
        ? extractHour(profile?.department?.shiftEnd)
        : extractHour(profile?.scheduleEnd ?? "23:00:00");
    const isTimePickerDisabled = () => {
        const startDayjs = dayjs(weekData.date);
        if (weekData.holidayType) {
            return true;
        }
        if (profile?.isFired) return true;
        if (startDayjs.month() !== dayjs().month()) return true;
        if (startDayjs.isAfter(dayjs().endOf("week"))) return true;
    };
    return weekData.holidayType || profile?.isFired ? (
        <div style={{ display: "flex", gap: "12px" }}>
            <TimePicker
                id="timepicker1"
                value={profile?.scheduleBegin}
                is24HourFormat={true}
                showSeconds={false}
                disabled={true}
            />
            <span> - </span>
            <TimePicker
                id="timepicker2"
                value={profile?.scheduleEnd}
                is24HourFormat={true}
                showSeconds={false}
                disabled={true}
            />
        </div>
    ) : (
        <div style={{ display: "flex", gap: "12px" }}>
            <TimePicker
                id="timepicker1"
                value={dayBegin}
                onChange={(e) => setDayBegin(e?.value ?? undefined)} // Use ?? to default to undefined if value is null
                is24HourFormat={true}
                showSeconds={false}
                minHour={minHour}
                maxHour={maxHour}
                hideNowButton={true}
                hideConfirmButton={false}
                disabled={isTimePickerDisabled()}
            />
            <span> - </span>
            <TimePicker
                id="timepicker2"
                value={dayEnd}
                onChange={(e) => setDayEnd(e?.value ?? undefined)} // Use ?? to default to undefined if value is null
                is24HourFormat={true}
                showSeconds={false}
                minHour={minHour}
                maxHour={maxHour}
                hideNowButton={true}
                hideConfirmButton={false}
                disabled={isTimePickerDisabled()}
            />
        </div>
    );
};
