import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { getMonday, ParseDate } from "../../utils/dateUtils";
import { ManagerStatsModel } from "../../models/ManagerStatsModel";

// Fetch function to get validation stats
const fetchValidationStatsForManager = async (
    token: string,
    begin: Date,
    end: Date,
): Promise<ManagerStatsModel[]> => {
    const monday = ParseDate(getMonday(begin));
    const friday = ParseDate(end);

    const url = `${baseURL}/api/Manager/GetValidationStats/${monday}/${friday}`;

    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch validation stats");
    }

    return await response.json();
};

// Custom hook using useQuery
export const useValidationStatsForManager = (token: string, begin: Date, end: Date) => {
    return useQuery<ManagerStatsModel[], Error>({
        queryKey: ["validationStats", begin, end],
        queryFn: () => fetchValidationStatsForManager(token, begin, end),
        enabled: !!token,
    });
};
