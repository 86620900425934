import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../constants";
import { useContext } from "react";
import { TimesheetContext } from "../contexts/TimesheetContext";
import { DepartmentModel } from "../models/DepartmentModel";

const fetchDepartments = async (token: string): Promise<DepartmentModel[]> => {
    const response = await fetch(`${baseURL}/api/Department/GetAll`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) throw new Error("Failed to fetch departments");
    return await response.json();
};

export const useGetDepartments = () => {
    const { accessToken: token } = useContext(TimesheetContext);
    return useQuery<DepartmentModel[], Error>({
        queryKey: ["departments"],
        queryFn: () => fetchDepartments(token),
        enabled: !!token,
    });
};
