import React, { useContext, useEffect } from "react";
import "./App.css";
import { Spinner, ToastContainer } from "@tag/tag-components-react-v4";
import { TimesheetContext } from "./contexts/TimesheetContext";
import { fetchAccessTokenWithMsal } from "./api/useGetToken";
import { useProfile } from "./api/ProfileApi/useProfile";
import { SideMenu } from "./components/SideMenu/SideMenu";
import { Pages } from "./Pages/Pages";
import { TOAST_CONTAINER_ID } from "./constants";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./msalAuthconfig";

function App() {
    const { instance } = useMsal();
    const { setProfile, accessToken, setAccessToken, isProfileLoading, setIsProfileLoading } =
        useContext(TimesheetContext);
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchAccessTokenWithMsal(instance, loginRequest);
            if (response) setAccessToken(response);
        };
        fetchData();
    }, [instance, setAccessToken]);

    const { data: profileData, isLoading: isGetProfileLoading } = useProfile(accessToken);

    useEffect(() => {
        if (profileData) setProfile(profileData);
        if (isProfileLoading) setIsProfileLoading(isGetProfileLoading);
    }, [profileData, isGetProfileLoading, isProfileLoading, setProfile, setIsProfileLoading]);

    // Conditional rendering for loading states
    if (!accessToken || isProfileLoading) {
        return <Spinner>Loading</Spinner>;
    }

    return (
        <div style={{ height: "100vh", overflowX: "hidden", overflowY: "auto" }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 8fr" }}>
                <SideMenu />
                <Pages />
                <ToastContainer id={TOAST_CONTAINER_ID} />
            </div>
        </div>
    );
}

export default App;
