import React, { useContext, useState } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import {
	Card,
	H4,
} from "@tag/tag-components-react-v4";
import { ManagerAddHolidayForm } from "./ManagerAddHolidayForm";
import { useValidationStatsForManager } from "../../api/ManagersApi/useValidationStatsForManager";
import { ManagersValidationLeaves } from "./ManagersValidationLeaves";
import { Divider } from "../commons/Divider";
import { getMonday } from "../../utils/dateUtils";
import dayjs from "dayjs";

export type ManagerLeavesProps = {};

export const ManagerLeaves = (props: ManagerLeavesProps) => {
	const { accessToken } = useContext(TimesheetContext);

	const [startDate, setStartDate] = useState<Date>(
			getMonday(dayjs().toDate()) // Monday
		);
		const [endDate, setEndDate] = useState<Date>(
			dayjs(startDate).startOf("week").add(5, "day").toDate() // Friday
		);

	const {
		data: statsForManager,
		isLoading: isStatsLoading,
		error: statsError,
	} = useValidationStatsForManager(accessToken, startDate, endDate);
	return (
        <div>
            <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                <H4> Leaves </H4>
            </Card>
            <div
                style={{
                    marginLeft: "1%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                }}
            >
                <div style={{ width: "30rem" }}>
                    <Divider content={"Add Leave"} orientation={"center"} />
                    <ManagerAddHolidayForm statsForManager={statsForManager} />
                </div>
                <div style={{ width: "60rem", marginTop: "-5px", marginRight: "5%" }}>
                    <Divider content={"Edit Leaves"} orientation={"center"} />
                    <ManagersValidationLeaves />
                </div>
            </div>
        </div>
    );
};
