import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants";

const updateProfileSchedule = async (begin: string, end: string, token: string): Promise<any> => {
    const schedule = { scheduleBegin: begin, scheduleEnd: end };
    const response = await fetch(`${baseURL}/api/Profile/UpdateSchedule`, {
        method: "PATCH", // *GET, POST, PUT, DELETE, etc.
        headers: {
            "Content-Type": "application/json",

            Authorization: "Bearer " + token,
            // "Access-Control-Allow-Origin": `${baseURL}`,
        },
        body: JSON.stringify(schedule), // body data type must match "Content-Type" header
    });
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Update failed: ${response.status} ${errorText}`);
    }

    return await response.status;
};

export const useUpdateProfileSchedule = () => {
    return useMutation({
        mutationFn: ({ begin, end, token }: { begin: string; end: string; token: string }) =>
            updateProfileSchedule(begin, end, token),
    });
};
