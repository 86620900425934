import dayjs from "dayjs";
import { DevLogsModel } from "../../models/DevLogsModel";

export const sortLogs = (logs: DevLogsModel[]): DevLogsModel[] => {
    return logs.sort((a, b) => {
        const dateA = dayjs(a.doneAt);
        const dateB = dayjs(b.doneAt);
        return dateA.isBefore(dateB) ? 1 : dateA.isAfter(dateB) ? -1 : 0;
    });
};
export const filterLogs = (logs: DevLogsModel[], searchCriteria: string): DevLogsModel[] => {
    return logs.filter(
        (a) =>
            a.doneAt.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
            a.action.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
            a.level.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
            a.details.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
            a.doneBy.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1,
    );
};
export const getLogsPages = (logs: DevLogsModel[], page: number, pageSize: number) => {
    return logs.slice((page - 1) * pageSize, page * pageSize).map((obj) => ({
        ...obj,
        doneAt: obj.doneAt,
        action: obj.action,
        level: obj.level,
        details: obj.details,
        doneBy: obj.doneBy,
    }));
};

export const logsMock: DevLogsModel[] = [
    {
        id: "1",
        doneAt: "2024-12-01T14:30:00Z",
        action: "Deployed application",
        level: "INFO",
        details: "Successfully deployed the latest version of the app to production.",
        doneBy: "John Doe",
    },
    {
        id: "2",
        doneAt: "2024-12-02T10:15:00Z",
        action: "Fixed bug",
        level: "DEBUG",
        details: "Resolved issue causing incorrect data on the user dashboard.",
        doneBy: "Jane Smith",
    },
    {
        id: "3",
        doneAt: "2024-11-30T08:45:00Z",
        action: "Updated dependencies",
        level: "WARN",
        details: "Updated vulnerable packages in the project dependencies.",
        doneBy: "Michael Brown",
    },
    {
        id: "4",
        doneAt: "2024-12-01T16:00:00Z",
        action: "Performed database migration",
        level: "CRITICAL",
        details: "Applied schema changes for the new feature release. Rollback plan prepared.",
        doneBy: "Sarah Johnson",
    },
    {
        id: "5",
        doneAt: "2024-11-29T13:20:00Z",
        action: "Code review completed",
        level: "INFO",
        details: "Reviewed pull request #87 for code quality and adherence to standards.",
        doneBy: "Emily Davis",
    },
    {
        id: "6",
        doneAt: "2024-12-01T18:30:00Z",
        action: "Implemented feature",
        level: "INFO",
        details: "Added user profile editing functionality to the application.",
        doneBy: "David Wilson",
    },
    {
        id: "7",
        doneAt: "2024-12-02T09:00:00Z",
        action: "Test cases added",
        level: "DEBUG",
        details: "Created and executed 15 new test cases for the payment module.",
        doneBy: "Sophia Lee",
    },
    {
        id: "8",
        doneAt: "2024-12-02T12:45:00Z",
        action: "Rollback executed",
        level: "ERROR",
        details: "Rolled back the deployment due to a critical error in the database migration.",
        doneBy: "Oliver Martinez",
    },
    {
        id: "9",
        doneAt: "2024-12-02T14:30:00Z",
        action: "Updated CI/CD pipeline",
        level: "INFO",
        details: "Optimized the CI/CD pipeline to reduce build and deployment times by 30%.",
        doneBy: "Liam White",
    },
    {
        id: "10",
        doneAt: "2024-12-01T21:00:00Z",
        action: "Error monitoring configured",
        level: "INFO",
        details: "Set up monitoring for server errors and automated alerts in case of failures.",
        doneBy: "Mia Anderson",
    },
    {
        id: "11",
        doneAt: "2024-12-02T07:15:00Z",
        action: "Database backup completed",
        level: "INFO",
        details: "Full database backup completed successfully before the migration.",
        doneBy: "Noah Hernandez",
    },
    {
        id: "12",
        doneAt: "2024-12-01T23:30:00Z",
        action: "Performance testing",
        level: "DEBUG",
        details:
            "Conducted load tests to ensure the application can handle 10,000 concurrent users.",
        doneBy: "Ava Martinez",
    },
    {
        id: "13",
        doneAt: "2024-11-30T19:45:00Z",
        action: "Security patch applied",
        level: "WARN",
        details:
            "Applied a security patch to address vulnerabilities in the authentication service.",
        doneBy: "Isabella Garcia",
    },
    {
        id: "14",
        doneAt: "2024-12-02T16:00:00Z",
        action: "Refactored codebase",
        level: "INFO",
        details: "Improved code readability and modularity in the user service module.",
        doneBy: "Ethan Moore",
    },
    {
        id: "15",
        doneAt: "2024-11-30T15:30:00Z",
        action: "Resolved production issue",
        level: "CRITICAL",
        details: "Identified and resolved a production issue causing downtime for some users.",
        doneBy: "Emma Taylor",
    },
];
