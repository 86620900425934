import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants";

const deleteHolidayManager = async (id: string, token: string) => {
    const response = await fetch(`${baseURL}/api/Manager/DeleteHoliday/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "text/json",
            Accept: "text/json",
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to delete holiday. Status: ${response.status}`);
    }

    return { statusCode: response.status };
};

export const useDeleteHolidayManager = () => {
    return useMutation<{ statusCode: number }, Error, { id: string; token: string }>({
        mutationFn: ({ id, token }: { id: string; token: string }) =>
            deleteHolidayManager(id, token),
    });
};
