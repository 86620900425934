import { useMutation, useQueryClient } from "@tanstack/react-query";
import { baseURL, ERROR_COULDNT_UPDATE_WORKLOCATION } from "../../constants";
const updateWorkLocationAdmin = async (id: string, typeId: number, token: string): Promise<any> => {
    const body = { workTypeId: typeId };
    const response = await fetch(`${baseURL}/api/Admin/WorkLocationSchedule/Update/${id}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "text/json",
            Accept: "text/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error(ERROR_COULDNT_UPDATE_WORKLOCATION);
    return response.status;
};

export const useUpdateWorkLocationAdmin = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["addUpdateWorkLocationAdmin"],
        mutationFn: ({ id, typeId, token }: { id: string; typeId: number; token: string }) =>
            updateWorkLocationAdmin(id, typeId, token),
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
};
