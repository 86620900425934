import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    baseURL,
    ERROR_COULDNT_FILL_WEEK,
    SUCCESS_FILL_WEEK,
    TOAST_CONTAINER_ID,
} from "../../constants";
import { useToasts } from "@tag/tag-components-react-v4";
import { FillWeekTimesheet } from "../../models/FillWeekTimesheet";

const fillWeek = async (week: FillWeekTimesheet, token: string): Promise<any> => {
    const response = await fetch(`${baseURL}/api/Timesheet/FillMe`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(week),
    });

    if (!response.ok) throw new Error("Failed to fill week.");
    return  await response.json();
};

export const useFillWeek = () => {
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);

    return useMutation({
        mutationKey: ["fillWeek"],
        mutationFn: ({ week, token }: { week: FillWeekTimesheet; token: string }) =>
            fillWeek(week, token),
        onSuccess: () => {
            queryClient.invalidateQueries();
            toast(SUCCESS_FILL_WEEK, { toastType: "success" });
        },
        onError: () => toast(ERROR_COULDNT_FILL_WEEK, { toastType: "error" }),
    });
};
