import { Button, P, SquareButton } from "@tag/tag-components-react-v4";
import { ChevronLeftLined, ChevronRightLined } from "@tag/tag-icons";
import React from "react";
import { monthNames } from "../../../utils/dateUtils";

export type TimesheetNavProps = {
	startDate: Date;
	endDate: Date;
	handlePrevOrNextWeek: (direction: "prev" | "next") => void;
	handleCurrentWeek: () => void;
};

export const TimesheetNav = (props: TimesheetNavProps) => {
	const { startDate, endDate, handlePrevOrNextWeek, handleCurrentWeek } = props;

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				gap: "12px",
				fontSize: "24px",
				alignItems: "center",
			}}
		>
			<Button
				onClick={handleCurrentWeek}
				accent="teal"
			>
				Current week
			</Button>
			<SquareButton
				accent="teal"
				icon={<ChevronLeftLined />}
				onClick={() => handlePrevOrNextWeek("prev")}
			/>
			<P style={{ fontSize: "15px", width: "150px", textAlign: "center" }}>
				{startDate.getDate() +
					" " +
					monthNames[startDate.getMonth()] +
					" - " +
					" " +
					endDate.getDate() +
					" " +
					monthNames[endDate.getMonth()] +
					" " +
					endDate.getFullYear()}
			</P>
			<SquareButton
				accent="teal"
				icon={<ChevronRightLined />}
				onClick={() => handlePrevOrNextWeek("next")}
			/>
		</div>
	);
};
