import {
    ListItem,
    List,
    ListItemElement,
    ListItemPrimaryElement,
} from "@tag/tag-components-react-v4";
import { BadApplesModel } from "../../models/BadApplesModel";
import { UserFilled } from "@tag/tag-icons";
export type BadApplesListProps = {
    isLastWeek: boolean;
    badApples: BadApplesModel[] | undefined;
};
// TODO: Prepared for PAGINATION
export const BadApplesList = (props: BadApplesListProps) => {
    const { isLastWeek, badApples } = props;
    return (
        <div style={{ width: "30rem", margin: "1% 1% 0 1%" }}>
            <List style={{ height: "320px" }}>
                {badApples?.map((badApple, _index) => (
                    <ListItem
                        key={`bad-apple-${badApple.employee.marca}-${
                            isLastWeek ? "last-week" : "this-month"
                        }`}
                    >
                        <ListItemElement icon={<UserFilled />}>
                            <ListItemPrimaryElement>
                                {" "}
                                {badApple.employee.fullName}
                            </ListItemPrimaryElement>

                            {badApple.employee.email}
                        </ListItemElement>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};
