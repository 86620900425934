import React, { useState, useEffect, useMemo } from "react";
import { MsalProvider } from "@azure/msal-react";
import { Spinner } from "@tag/tag-components-react-v4"; // Replace with your spinner component
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msalAuthconfig";

interface MsalInitializerProps {
    children: React.ReactNode;
}

const instance = new PublicClientApplication(msalConfig);

export const MsalInitializer: React.FC<MsalInitializerProps> = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        // Initialize MSAL and ensure handleRedirectPromise is called and completed
        const initializeMsal = async () => {
            try {
                await instance.initialize(); // Initialize MSAL instance
                await instance.handleRedirectPromise();
                setIsInitialized(true); // Set initialized flag after MSAL has been processed
            } catch (error) {
                console.error("Error during MSAL initialization:", error);
            }
        };

        // Call the initialization method on mount
        initializeMsal();
    }, []);

    // If MSAL initialization is still in progress, show a loading spinner
    if (!isInitialized) {
        return <Spinner>Loading...</Spinner>; // You can customize this with your own spinner or loading UI
    }

    // Once MSAL is initialized, render the rest of your app
    return <MsalProvider instance={instance}>{children}</MsalProvider>;
};
