import React, { useContext, useState } from "react";
import {
    IconContainer,
    ListItemPrimaryElement,
    Modal,
    Pill,
    SimpleButton,
    useToasts,
} from "@tag/tag-components-react-v4";
import { DeleteFilled, UserFilled } from "@tag/tag-icons";
import { getHolidayIcons } from "../../utils/getIconsWithAccents";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useDeleteHolidayManager } from "../../api/ManagersApi/useManagerDeleteHoliday";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { TOAST_CONTAINER_ID } from "../../constants";
import { useQueryClient } from "@tanstack/react-query";
export type ValidationLeavesItemProps = {
    holiday: any;
    page: number;
    setPage: (page: number) => void;
};
dayjs.extend(LocalizedFormat);
export const ValidationLeavesItem = (props: ValidationLeavesItemProps) => {
    const { holiday, page, setPage } = props;
    const queryClient = useQueryClient();
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);

    const [openDeleteHolidayFormModal, setOpenDeleteHolidayFormModal] = useState(false);

    const { mutate: onDeleteHoliday, isPending } = useDeleteHolidayManager();
    const handleHolidayDelete = () => {
        setOpenDeleteHolidayFormModal(true);
    };

    const handleSubmission = () => {
        onDeleteHoliday(
            { id: holiday.id, token: accessToken },
            {
                onSuccess: () => {
                    setOpenDeleteHolidayFormModal(false);
                    toast("Holiday deleted successfully!", { toastType: "success" });
                    queryClient.invalidateQueries();
                    setPage(1);
                },
                onError: (error) => {
                    toast(`Failed to delete holiday: ${error.message}`, { toastType: "error" });
                },
            },
        );
    };

    return (
        <div style={{ width: "100%" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: " 1fr repeat(4,200px)",
                        alignItems: "center",
                        justifyItems: "start",
                        justifyContent: "center",
                    }}
                >
                    <IconContainer>
                        <UserFilled />
                    </IconContainer>
                    <ListItemPrimaryElement>{holiday.profile}</ListItemPrimaryElement>
                    {/* Holiday Type and Dates */}
                    <Pill
                        variant="outlined"
                        style={{
                            display: "flex",
                            height: "30px",
                            width: "50px",
                            justifyContent: "center",
                            backgroundColor: "transparent",
                            border: `1px solid lightgrey`,
                        }}
                        accent={holiday.holiday.type.accent}
                    >
                        {/* Holiday Type Icon */}
                        <div>{getHolidayIcons(holiday.holiday.type)}</div>
                        {/* Holiday Short Name */}
                        <div>{holiday.holiday.type.shortName}</div>
                    </Pill>
                    <span>
                        <strong>From:</strong> {dayjs(holiday.begin).format("DD/MM/YYYY")}
                    </span>{" "}
                    <span>
                        <strong>To:</strong> {dayjs(holiday.end).format("DD/MM/YYYY")}
                    </span>
                </div>
                <SimpleButton
                    accent="teal"
                    startIcon={<DeleteFilled />}
                    onClick={handleHolidayDelete}
                >
                    Delete
                </SimpleButton>
            </div>
            <Modal
                suppressCloseButton
                heading="Are you sure?"
                visible={openDeleteHolidayFormModal}
                onClose={() => setOpenDeleteHolidayFormModal(false)}
                onPrimaryButtonClick={handleSubmission}
                primaryButtonProps={{ text: "Delete", icon: <DeleteFilled accent="white" /> }}
            ></Modal>
        </div>
    );
};
