import dayjs from "dayjs";
import { ProfileModel } from "../models/ProfileModel";
import { TimesheetModel } from "../models/TimesheetModel";
import { CreateTimesheetModel } from "../models/CreateTimesheetModel";

export interface DayChanges {
    dayBegin: string | undefined;
    dayEnd: string | undefined;
    workType: number | undefined;
}

export const isToday = (date: Date) => {
    const today = new Date();
    return (
        new Date(date).getFullYear() === today.getFullYear() &&
        new Date(date).getMonth() === today.getMonth() &&
        new Date(date).getDate() === today.getDate()
    );
};

export const parseTimeIntoHours = (time: string): number => {
    return parseInt(time.split(":")[0], 10);
};

export const getDayWorkedHours = (start: string, end: string): number => {
    const startTime = parseTimeIntoHours(start);
    const endTime = parseTimeIntoHours(end);
    return endTime - startTime;
};

export const isSameWithWorkingHours = (dayWorkedHours: number, workingHours: number): boolean => {
    return dayWorkedHours === workingHours;
};

export const disableToggle = (
    profileData: ProfileModel | null,
    weekData: TimesheetModel | null,
): boolean => {
    const startDayjs = dayjs(weekData?.date);
    if (startDayjs.isBefore(dayjs().startOf("month"))) return true;
    if (startDayjs.isAfter(dayjs().endOf("week"))) return true;
    if (profileData?.isFired) return true;
    if (weekData?.holidayType) return true;
    return false;
};

export const isLessOrMoreThanExpected = (dayWorkedHours: number, workingHours: number): boolean => {
    return dayWorkedHours < workingHours || dayWorkedHours > workingHours;
};

export const prepareTimesheet = (
    dayBegin: string,
    dayEnd: string,
    timesheet: TimesheetModel,
): CreateTimesheetModel => {
    return {
        id: timesheet.id,
        date: timesheet.date,
        dayBegin: dayBegin,
        dayEnd: dayEnd,
        isValid: false,
    };
};

export const initializeDayChanges = (weekLength: number) =>
    Array(weekLength).map(() => ({
        dayBegin: undefined,
        dayEnd: undefined,
        workType: undefined,
        isChanged: false,
    }));
