import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { UpdateTimesheetAdminModel } from "../../models/UpdateTimesheetAdminModel";

const updateAdminTimeSheet = async (
    id: number,
    day: UpdateTimesheetAdminModel,
    token: string,
): Promise<any> => {
    const response = await fetch(`${baseURL}/api/Admin/AddOrUpdateTimesheet/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "text/json",
            Accept: "text/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(day),
    });
    const data = await response.text();
    if (response.status !== 200) {
        throw new Error(`Update failed with status: ${response.status}`);
    }
    return { data: data, status: response.status };
};

export const useAdminUpdateTimeSheet = () => {
    return useMutation({
        mutationFn: ({
            id,
            day,
            token,
        }: {
            id: number;
            day: UpdateTimesheetAdminModel;
            token: string;
        }) => updateAdminTimeSheet(id, day, token),
    });
};
