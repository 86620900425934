import { Configuration, PublicClientApplication } from "@azure/msal-browser";
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const msalConfig: Configuration = {
    auth:{
         clientId: "afa49e3a-52f3-49ef-8776-030048610835", // Fallback to empty string if not set
         authority: "https://login.microsoftonline.com/739195a1-f5d6-4d9a-ac42-a1dbb7c7413d/",
         redirectUri: process.env.REACT_APP_REDIRECT_URL,
 
    },
     cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
}

export const msalInstance = new PublicClientApplication(msalConfig);
export const logoutRequest = {
    account: msalInstance.getActiveAccount(),
};

export const loginRequest = {
    scopes: ["api://afa49e3a-52f3-49ef-8776-030048610835/timesheet-api"],
};


export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
