import {
    Autocomplete,
    DatePicker,
    FormField,
    ModalForm,
    P,
    TextFeature,
    useToasts,
} from "@tag/tag-components-react-v4";
import DayOffModalStyles from "./DayOffModal.module.scss";
import { useFormik } from "formik";
import {
    LeaveData,
    prepareFormValuesForSubmit,
    setModalInitialValues,
} from "../../utils/leavesPageUtils";
import { validate } from "../../utils/leavesPageUtils";
import { useContext, useEffect } from "react";
import { useAddUpdateHoliday } from "../../api/useAddUpdateHoliday";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import _ from "lodash";
import {
    ERROR_COULDNT_ADDUPDATE_DAY_OFF,
    INFO_NO_CHANGES_DETECTED,
    TOAST_CONTAINER_ID,
} from "../../constants";

export const DayOffModal = ({
    initialValues,
    onCancel,
}: {
    initialValues: LeaveData | null;
    onCancel: () => void;
}) => {
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const { mutateAsync: addUpdateHoliday } = useAddUpdateHoliday();
    const formik = useFormik({
        initialValues: setModalInitialValues(initialValues),
        validate,
        onSubmit: async (values) => {
            if (_.isEqual(formik.initialValues, values))
                toast(INFO_NO_CHANGES_DETECTED, { toastType: "information" });
            else {
                const preparedValues = prepareFormValuesForSubmit(values, initialValues);

                await addUpdateHoliday({
                    holiday: preparedValues,
                    token: accessToken,
                }).catch(() => toast(ERROR_COULDNT_ADDUPDATE_DAY_OFF, { toastType: "error" }));
            }
            onCancel();
        },
    });
    useEffect(() => {
        if (!formik.values.begin || (formik.values.end && formik.values.begin > formik.values.end))
            formik.setFieldValue("end", null);
    }, [formik.values.begin]);

    const todayDate = new Date();
    const futureDate = new Date();
    futureDate.setDate(todayDate.getDate() + 7);
    const isWeekend = (date: any) => {
        const day = date.getDay();
        return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
    };
    return (
        <ModalForm
            visible={true}
            heading={`${initialValues ? "Edit" : "Book"} Day Off`}
            submitButtonProps={{ text: "Save", style: { padding: "0px 32px" } }}
            cancelButtonProps={{ text: "Exit", style: { padding: "0px 32px" } }}
            onSubmit={formik.handleSubmit}
            onClose={() => onCancel()}
            headingProps={{
                style: { fontWeight: "bold" },
            }}
        >
            <div className={DayOffModalStyles.dateContainer}>
                <div>
                    <P className={DayOffModalStyles.boldParagraph}>Start</P>
                    <FormField
                        invalid={!!(formik.errors.begin && formik.touched.begin)}
                        validationErrors={formik.errors.begin}
                        editor={
                            <DatePicker
                                {...formik.getFieldProps("begin")}
                                style={{ width: "100%" }}
                                clearButton
                                minDate={new Date()}
                                getDayProps={(date) => {
                                    const isToday =
                                        date.toDateString() === todayDate.toDateString();
                                    const pastDate = date < todayDate && !isToday;
                                    return {
                                        disabled: pastDate || isWeekend(date),
                                    };
                                }}
                                onChange={(e) => {
                                    formik.setFieldValue("begin", e.utcDate);
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <P className={DayOffModalStyles.boldParagraph}>End</P>
                    <FormField
                        invalid={!!(formik.errors.end && formik.touched.end && formik.values.begin)}
                        validationErrors={formik.errors.end}
                        editor={
                            <DatePicker
                                {...formik.getFieldProps("end")}
                                style={{ width: "100%" }}
                                clearButton
                                disabled={!formik.values.begin}
                                minDate={formik.values.begin ?? undefined}
                                getDayProps={(date) => {
                                    const isToday =
                                        date.toDateString() === todayDate.toDateString();
                                    const pastDate = date < todayDate && !isToday;
                                    return {
                                        disabled: pastDate || isWeekend(date),
                                    };
                                }}
                                onChange={(e) => {
                                    formik.setFieldValue("end", e.utcDate);
                                }}
                            />
                        }
                    />
                </div>
                <div className={DayOffModalStyles.fullWidthColumn}>
                    <P className={DayOffModalStyles.boldParagraph}>Day Off Type</P>
                    <FormField
                        invalid={!!(formik.errors.typeId && formik.touched.typeId)}
                        validationErrors={formik.errors.typeId}
                        editor={
                            <Autocomplete
                                {...formik.getFieldProps("typeId")}
                                id="typeId"
                                textField="name"
                                valueField="id"
                                data={[
                                    { id: 1, name: "CO – Annual Leave" },
                                    { id: 3, name: "CPL – Special Leave (RO)" },
                                    { id: 8, name: "D – Delegation" },
                                    { id: 9, name: "BB – Big Break" },
                                ]}
                                renderItem={(item) => (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <TextFeature style={{ marginLeft: "8px" }}>
                                            {item.name}
                                        </TextFeature>
                                    </div>
                                )}
                                noResultsElement={
                                    <P style={{ padding: "8px" }}>No holiday types found.</P>
                                }
                                style={{ width: "100%" }}
                                clearButton
                            />
                        }
                    />
                </div>
            </div>
        </ModalForm>
    );
};
