import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants"; // Adjust path if necessary
import { HolidayModel } from "../../models/HolidayModel"; // Import your model for type safety

// The function to handle the API call
const addHolidayManager = async (
  holiday: HolidayModel,
  
  email: string,
  token: string,
): Promise<any> => {
  const response = await fetch(`${baseURL}/api/Manager/AddOrUpdateHoliday/${email}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(holiday), // Sending the holiday data as JSON in the request body
  });

  if (!response.ok) {
    throw new Error("Failed to add or update holiday.");
  }

  return await response.json();
};

// Custom hook using useMutation
export const useAddHolidayManager = () => {
  return useMutation({
    mutationFn: ({ holiday, email, token }: { holiday: HolidayModel; token: string; email: string }) =>
      addHolidayManager(holiday,  email, token,),
  });
};
