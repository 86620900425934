import { Card, Pager } from "@tag/tag-components-react-v4";
import { ManagerBadApplesTabs } from "./ManagerBadApplesTabs";
import { useBadApples } from "../../api/useBadApples";
import { useContext, useState } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { IncompleteListModel } from "../../models/IncompleteTimesheetsModel";

export const ManagerBadApplesView = () => {
    const { accessToken } = useContext(TimesheetContext);
    const [lastWeekPage, setLastWeekPage] = useState<number>(1);
    const [thisMonthPage, setThisMonthPage] = useState<number>(1);

    const {
        data: badApplesData,
        isLoading: isBadApplesLoading,
        error,
    } = useBadApples(lastWeekPage, thisMonthPage, accessToken);
    return (
        <div style={{ margin: "1%", display: "flex", gap: "10rem" }}>
            <div>
                <Card style={{ textAlign: "center", padding: "1%", backgroundColor: "#db7093" }}>
                    <strong>Last Week</strong>
                </Card>
                <ManagerBadApplesTabs
                    isLastWeek={true}
                    isLoading={isBadApplesLoading}
                    error={error}
                    page={lastWeekPage}
                    setPage={setLastWeekPage}
                    badApplesData={badApplesData?.lastWeek}
                />
            </div>
            <div>
                <Card style={{ textAlign: "center", padding: "1%", backgroundColor: "#db7093" }}>
                    <strong>This Month</strong>
                </Card>
                <ManagerBadApplesTabs
                    isLastWeek={false}
                    isLoading={isBadApplesLoading}
                    error={error}
                    badApplesData={badApplesData?.thisMonth}
                    page={thisMonthPage}
                    setPage={setThisMonthPage}
                />
            </div>
        </div>
    );
};
