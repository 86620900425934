import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../constants";
import { IncompleteTimesheetsModel } from "../models/IncompleteTimesheetsModel";

const fetchBadApples = async (
    lastWeekCurrentPage: number,
    lastMonthCurrentPage: number,
    token: string,
): Promise<IncompleteTimesheetsModel> => {
    const pageSize: number = 5;
    const response = await fetch(
        `${baseURL}/api/Manager/GetBadApples?lastWeekTake=${pageSize}&lastWeekSkip=${
            (lastWeekCurrentPage - 1) * pageSize
        }&thisMonthTake=${pageSize}&thisMonthSkip=${(lastMonthCurrentPage - 1) * pageSize}`,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );

    if (!response.ok) {
        throw new Error(`Failed to fetch bad apples: ${response.statusText}`);
    }

    return await response.json();
};

export const useBadApples = (
    lastWeekCurrentPage: number,
    lastMonthCurrentPage: number,
    token: string,
) => {
    return useQuery<IncompleteTimesheetsModel, Error>({
        queryKey: ["badApples", lastWeekCurrentPage, lastMonthCurrentPage],
        queryFn: () => fetchBadApples(lastWeekCurrentPage, lastMonthCurrentPage, token),
        enabled: Boolean(token),
        retry: 1,
    });
};
