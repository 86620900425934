import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { UpdateTimesheetAdminModel } from "../../models/UpdateTimesheetAdminModel";

const updateManagerTimesheet = async (
	id: number,
	day: UpdateTimesheetAdminModel,
	token: string
): Promise<any> => {
	const response = await fetch(`${baseURL}/api/Manager/AddOrUpdateTimesheet/${id}`, {
		method: "POST", // *GET, POST, PUT, DELETE, etc.
		headers: {
			"Content-Type": "text/json",
			Accept: "text/json",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify(day), // body data type must match "Content-Type" header
	});
	const data = await response.text();
	if (response.status !== 200) {
		throw new Error(`Update failed with status: ${response.status}`);
	}
	return { data: data, status: response.status };
};
export const useManagerUpdateTimesheet = () => {
	return useMutation({
		mutationFn: ({
			id,
			day,
			token,
		}: {
			id: number;
			day: UpdateTimesheetAdminModel;
			token: string;
		}) => updateManagerTimesheet(id, day, token),
	});
};
