import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);
export const ParseDate = (date: Date): string => {
	const Add0IfMissing = (d: number) => (d < 10 ? "0" + d : d);

	return (
		date.getFullYear() +
		"-" +
		Add0IfMissing(date.getMonth() + 1) +
		"-" +
		Add0IfMissing(date.getDate())
	);
};

export const getMonday = (date: Date): Date => {
	return dayjs(date).startOf("week").add(1, "day").toDate();
};

export const monthNames = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

export const makeTimes = () => {
	return Array.from(Array(57)).map((_v, i) => {
		const hours = 7 + Math.floor(i / 4);
		const minutes = (i % 4) * 15;
		return {
			id: i + 1,
			time: `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`,
		};
	});
};

export const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];
