import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { TimesheetContext } from '../contexts/TimesheetContext';
import {
    DatePicker,
    FormField,
    H3,
    Input,
    Label,
    Pill,
    SimpleButton,
    Spinner,
    TimePicker,
    useToasts,
} from '@tag/tag-components-react-v4';
import { ErrorCard } from '../components/commons/ErrorCard';
import { BuildingFilled, HomeFilled, SaveDiskFilled } from '@tag/tag-icons';
import { getDayWorkedHours } from '../utils/TimesheetUtils';
import { TOAST_CONTAINER_ID } from '../constants';
import { useUpdateProfileSchedule } from "../api/ProfileApi/useProfileUpdateSchedule";
import { useQueryClient } from '@tanstack/react-query';

export const ProfilePage = () => {
    const { profile, accessToken, isProfileLoading } = useContext(TimesheetContext);
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const [startTime, setStartTime] = useState<string>('');
    const [endTime, setEndTime] = useState<string>('');

    useEffect(() => {
        if (profile) {
            setStartTime(profile?.scheduleBegin?.substring(0, 5) || '');
            setEndTime(profile?.scheduleEnd?.substring(0, 5) || '');
        }
    }, [profile]);
    const office = profile?.workLocationSchedule
        .filter(({ workType }) => workType.id === 1)
        .map(({ dayOfWeek }) => dayOfWeek.substring(0, 3));

    const home = profile?.workLocationSchedule
        .filter(({ workType }) => workType.id === 2)
        .map(({ dayOfWeek }) => dayOfWeek.substring(0, 3));
    const minMaxHours = [
        parseInt((profile?.department.shiftBegin ?? '00:00').split(':')[0]),
        parseInt((profile?.department.shiftEnd ?? '0:00').split(':')[0]),
    ];

    const { mutate: updateProfileSchedule, isPending, error } = useUpdateProfileSchedule();
    const handleSave = () => {
        if (!startTime || !endTime) {
            toast('Start and End times must be filled', { toastType: 'error' });
            return;
        }

        const isValidHours = getDayWorkedHours(startTime, endTime) === profile?.workingHours;
        if (!isValidHours) {
            toast('The new hours are not the same as the working hours', { toastType: 'error' });
            return;
        }
        updateProfileSchedule(
            { begin: startTime, end: endTime, token: accessToken },
            {
                onError: (error) => {
                    toast(`Error updating schedule : ${error.message}`, { toastType: 'error' });
                },
                onSuccess: (data) => {
                    queryClient.invalidateQueries();
                    toast('Schedule updated', { toastType: 'success' });
                },
            },
        );
    };
    return isProfileLoading ? (
        <Spinner>Loading...</Spinner>
    ) : profile !== null ? (
        <div style={{ margin: "2%" }}>
            <H3 style={{ fontSize: "1.75rem", fontWeight: "bold" }}>{profile?.fullName}</H3>
            <Pill
                variant="outlined"
                style={{
                    backgroundColor: "#036d8326",
                    color: "#036d83",
                    border: "none",
                    marginTop: "1%",
                    fontSize: "1rem",
                    width: "15rem",
                    height: "2rem",
                    display: "inline-flex",
                    justifyContent: "center",
                }}
            >
                <span style={{ padding: "1%", fontWeight: "500" }}>{profile?.department.name}</span>
            </Pill>

            <FormField
                style={{ marginTop: "1%" }}
                label="Email"
                disabled
                editor={<Input value={profile.email} style={{ color: "black", width: "100%" }} />}
            />

            <FormField
                label="Manager Email"
                disabled
                editor={
                    <Input value={profile.managerEmail} style={{ color: "black", width: "100%" }} />
                }
            />

            <FormField
                label="Start Date"
                disabled
                editor={
                    <DatePicker
                        style={{ width: "100%" }}
                        disabled
                        inputStyle={{ color: "black" }}
                        value={new Date(profile.startDate)}
                    />
                }
            />
            <FormField
                label="Working Hours"
                disabled
                editor={
                    <Input value={profile.workingHours} style={{ color: "black", width: "100%" }} />
                }
            />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <FormField
                        label="Office"
                        disabled
                        editor={<Input value={office} style={{ color: "black", width: "100%" }} />}
                    />
                    <BuildingFilled size="large" />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <FormField
                        label="Home"
                        disabled
                        editor={<Input value={home} style={{ color: "black", width: "100%" }} />}
                    />
                    <HomeFilled size="large" />
                </div>

                <FormField
                    label="Work Practice"
                    disabled
                    editor={
                        <Input
                            value={profile.workPractice}
                            style={{ color: "black", width: "100%" }}
                        />
                    }
                />
                <FormField
                    label="Office Location"
                    disabled
                    editor={
                        <Input
                            value={profile.officeLocation}
                            style={{ color: "black", width: "100%" }}
                        />
                    }
                />
            </div>

            <Label>Working Schedule</Label>
            <div style={{ display: "flex", gap: "12px" }}>
                <TimePicker
                    id="profile-timepicker1"
                    onChange={(e) => setStartTime(e?.value ?? "")}
                    value={startTime}
                    is24HourFormat
                    showSeconds={false}
                    minHour={minMaxHours[0]}
                    maxHour={minMaxHours[1]}
                    hideNowButton
                    hideConfirmButton={false}
                />{" "}
                -{" "}
                <TimePicker
                    id="profile-timepicker1"
                    onChange={(e) => setEndTime(e.value ?? "")}
                    value={endTime}
                    is24HourFormat
                    showSeconds={false}
                    minHour={minMaxHours[0]}
                    maxHour={minMaxHours[1]}
                    hideNowButton
                    hideConfirmButton={false}
                />
            </div>
            <SimpleButton
                style={{ marginTop: "2%", width: "5rem" }}
                accent="teal"
                startIcon={<SaveDiskFilled />}
                onClick={handleSave}
                disabled={
                    !startTime ||
                    !endTime ||
                    getDayWorkedHours(startTime, endTime) !== profile?.workingHours
                }
            >
                Save{" "}
            </SimpleButton>
        </div>
    ) : (
        <ErrorCard error={new Error("Please refresh")} />
    );
};

