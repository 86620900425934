import { FormikProps } from "formik";
import {
    Autocomplete,
    DatePicker,
    FormField,
    Input,
    P,
    TextFeature,
    TimePicker,
} from "@tag/tag-components-react-v4";
import EmployeeModalStyles from "./EmployeeModal.module.scss";
import { useGetDepartments } from "../../api/useGetDepartments";
import {
    EmployeeFormFields,
    getMinMaxHours,
    isWeekend,
    offices,
} from "../../utils/employeesPageUtils";

export const EmployeeForm = ({ formik }: { formik: FormikProps<EmployeeFormFields> }) => {
    const { data: departments, isLoading } = useGetDepartments();
    const minMaxHours = getMinMaxHours(departments, formik.values.departmentId);

    return (
        <div className={EmployeeModalStyles.dateContainer}>
            <FormField
                label="First name"
                editor={<Input {...formik.getFieldProps("firstName")} style={{ width: "100%" }} />}
                invalid={!!(formik.errors.firstName && formik.touched.firstName)}
                validationErrors={formik.errors.firstName}
            />
            <FormField
                label="Last name"
                editor={<Input {...formik.getFieldProps("lastName")} style={{ width: "100%" }} />}
                invalid={!!(formik.errors.lastName && formik.touched.lastName)}
                validationErrors={formik.errors.lastName}
            />
            <FormField
                label="Email"
                editor={<Input {...formik.getFieldProps("email")} style={{ width: "100%" }} />}
                invalid={!!(formik.errors.email && formik.touched.email)}
                validationErrors={formik.errors.email}
            />
            <FormField
                label="Manager email"
                editor={
                    <Input {...formik.getFieldProps("managerEmail")} style={{ width: "100%" }} />
                }
                invalid={!!(formik.errors.managerEmail && formik.touched.managerEmail)}
                validationErrors={formik.errors.managerEmail}
            />
            <FormField
                label="Marca"
                editor={<Input {...formik.getFieldProps("marca")} style={{ width: "100%" }} />}
                invalid={!!(formik.errors.marca && formik.touched.marca)}
                validationErrors={formik.errors.marca}
            />
            <FormField
                label="Department"
                editor={
                    <Autocomplete
                        {...formik.getFieldProps("departmentId")}
                        textField="name"
                        valueField="id"
                        data={departments ?? []}
                        renderItem={(item) => (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <TextFeature style={{ marginLeft: "8px" }}>{item.name}</TextFeature>
                            </div>
                        )}
                        noResultsElement={<P style={{ padding: "8px" }}>No departments found.</P>}
                        isLoading={isLoading}
                        style={{ width: "100%" }}
                        clearButton
                    />
                }
                invalid={!!(formik.errors.departmentId && formik.touched.departmentId)}
                validationErrors={formik.errors.departmentId}
            />
            <FormField
                label="Start Date"
                editor={
                    <DatePicker
                        {...formik.getFieldProps("startDate")}
                        style={{ width: "100%" }}
                        clearButton
                        onChange={(e) => {
                            formik.setFieldValue("startDate", e.utcDate);
                        }}
                        getDayProps={(date) => {
                            return { disabled: isWeekend(date) };
                        }}
                    />
                }
                invalid={!!(formik.errors.startDate && formik.touched.startDate)}
                validationErrors={formik.errors.startDate}
            />
            <FormField
                label="Working Hours"
                editor={<Input {...formik.getFieldProps("workHours")} style={{ width: "100%" }} />}
                invalid={!!(formik.errors.workHours && formik.touched.workHours)}
                validationErrors={formik.errors.workHours}
            />
            <FormField
                label="Schedule Begin"
                editor={
                    <TimePicker
                        {...formik.getFieldProps("scheduleBegin")}
                        style={{ width: "100%" }}
                        is24HourFormat
                        showSeconds={false}
                        hideNowButton
                        hideConfirmButton={false}
                        minHour={minMaxHours?.[0] ?? 0}
                        maxHour={minMaxHours?.[1] ?? 23}
                        disabled={!formik.values.departmentId}
                    />
                }
                invalid={!!(formik.errors.scheduleBegin && formik.touched.scheduleBegin)}
                validationErrors={formik.errors.scheduleBegin}
            />
            <FormField
                label="Schedule End"
                editor={
                    <TimePicker
                        {...formik.getFieldProps("scheduleEnd")}
                        style={{ width: "100%" }}
                        is24HourFormat
                        showSeconds={false}
                        hideNowButton
                        hideConfirmButton={false}
                        minHour={minMaxHours?.[0] ?? 0}
                        maxHour={minMaxHours?.[1] ?? 23}
                        disabled={!formik.values.departmentId}
                    />
                }
                invalid={!!(formik.errors.scheduleEnd && formik.touched.scheduleEnd)}
                validationErrors={formik.errors.scheduleEnd}
            />
            <FormField
                label="Work Practice"
                editor={
                    <Input {...formik.getFieldProps("workPractice")} style={{ width: "100%" }} />
                }
                invalid={!!(formik.errors.workPractice && formik.touched.workPractice)}
                validationErrors={formik.errors.workPractice}
            />
            <FormField
                label="Office Location"
                editor={
                    <Autocomplete
                        {...formik.getFieldProps("officeLocation")}
                        textField="name"
                        valueField="name"
                        data={offices}
                        renderItem={(item) => (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <TextFeature style={{ marginLeft: "8px" }}>{item.name}</TextFeature>
                            </div>
                        )}
                        noResultsElement={<P style={{ padding: "8px" }}>No departments found.</P>}
                        style={{ width: "100%" }}
                        clearButton
                    />
                }
                invalid={!!(formik.errors.officeLocation && formik.touched.officeLocation)}
                validationErrors={formik.errors.officeLocation}
            />
            {/* <FormField
                label="R&D Eligibility"
                editor={<Input {...formik.getFieldProps("marca")} style={{ width: "100%" }} />}
                invalid={!!(formik.errors.marca && formik.touched.marca)}
                validationErrors={formik.errors.marca}
            />
            <FormField
                label="Activity"
                editor={<Input {...formik.getFieldProps("marca")} style={{ width: "100%" }} />}
                invalid={!!(formik.errors.marca && formik.touched.marca)}
                validationErrors={formik.errors.marca}
            /> */}
        </div>
    );
};
