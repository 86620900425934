import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { TimesheetModel } from "../../models/TimesheetModel";
import { ParseDate } from "../../utils/dateUtils";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { useContext } from "react";

// Helper function to format date as yyyy-mm-dd

const fetchTimesheetWeek = async (
    token: string,
    begin: Date,
    end: Date,
): Promise<TimesheetModel[]> => {
    const monday = new Date(begin);
    const newEnd = new Date(begin);
    newEnd.setDate(begin.getDate() + 4);
    const url = `${baseURL}/api/Timesheet/GetRange/${ParseDate(monday)}/${ParseDate(newEnd)}`;

    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        throw new Error("Error fetching timesheet data");
    }
    const data = await response.json();

  return data;
};

export const useTimesheetWeek = (begin: Date, end: Date) => {
    const {accessToken} = useContext(TimesheetContext);
    return useQuery<TimesheetModel[], Error>({
        queryKey: ["timesheetWeek", accessToken, begin, end],
        queryFn: () => fetchTimesheetWeek(accessToken, begin, end),
        enabled: !!accessToken,
    });
};
