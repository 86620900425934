import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../constants";
import { ProfileModel } from "../models/ProfileModel";
import { PaginationModel } from "../models/PaginationModel";

const fetchEmployees = async (
    employeesPage: number,
    pageSize: number,
    searchCriteria: string,
    token: string,
): Promise<PaginationModel<ProfileModel>> => {
    const response = await fetch(
        `${baseURL}/api/Admin/GetEmployees?take=${pageSize}&skip=${
            (employeesPage - 1) * pageSize
        }&searchCriteria=${searchCriteria}`,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );

    if (!response.ok) {
        throw new Error(`Failed to fetch employees: ${response.statusText}`);
    }

    return await response.json();
};

export const useGetEmployees = (
    employeesPage: number,
    pageSize: number,
    searchCriteria: string,
    token: string,
) => {
    return useQuery<PaginationModel<ProfileModel>, Error>({
        queryKey: ["employees", employeesPage, searchCriteria],
        queryFn: () => fetchEmployees(employeesPage, pageSize, searchCriteria, token),
        enabled: Boolean(token),
        retry: 1,
    });
};
