import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    baseURL,
    ERROR_COULDNT_ADDUPDATE_TIMESHEET,
    SUCCESS_ADDUPDATE_TIMESHEET,
    TOAST_CONTAINER_ID,
} from "../../constants";
import { useToasts } from "@tag/tag-components-react-v4";
import { CreateTimesheetModel } from "../../models/CreateTimesheetModel";
import dayjs from "dayjs";
const addUpdateTimesheet = async (timesheet: CreateTimesheetModel, token: string): Promise<any> => {
    const response = await fetch(`${baseURL}/api/Timesheet/AddOrUpdate`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(timesheet),
    });

    if (!response.ok)
        throw new Error(
            ERROR_COULDNT_ADDUPDATE_TIMESHEET + ` (${dayjs(timesheet.date).format("MM/DD/YYYY")})`,
        );
    return await response.status;
};

export const useAddUpdateTimesheet = () => {
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);

    return useMutation({
        mutationKey: ["addUpdateTimesheet"],
        mutationFn: ({ timesheet, token }: { timesheet: CreateTimesheetModel; token: string }) =>
            addUpdateTimesheet(timesheet, token),
        onSuccess: (data, { timesheet }) => {
            queryClient.invalidateQueries();
            toast(
                SUCCESS_ADDUPDATE_TIMESHEET + ` (${dayjs(timesheet.date).format("MM/DD/YYYY")})`,
                { toastType: "success" },
            );
        },
    });
};
