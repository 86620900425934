import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { HolidaysAdminModel } from "../../models/HolidaysAdminModel";

const fetchHolidaysManager = async (token: string, take?: number, skip?: number): Promise<{ totalCount: number; managerHolidayData: HolidaysAdminModel[]}> => {
    const url = take&&skip ?
    `${baseURL}/api/Manager/GetHolidays/take=${take}&skip=${skip}` :
    `${baseURL}/api/Manager/GetHolidays`;
    
    const response = await fetch(`${url}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch holidays data");
    }

    const data = await response.json();
    return { totalCount: data.totalCount, managerHolidayData: data.items }
};

export const useManagerHolidays = (token: string, take?: number, skip?: number) => {
    return useQuery<{ totalCount: number; managerHolidayData: HolidaysAdminModel[]}, Error>({
        queryKey: ["holidaysManager"],
        queryFn: () => fetchHolidaysManager(token, take, skip),
        enabled: !!token,
    });
};
