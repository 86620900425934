import { TimesheetModel } from "../models/TimesheetModel";

export const getCellStyles = (isSelected: boolean, day: TimesheetModel) => {
    if (isSelected) {
        return {
            color: "#4b4e52",
            backgroundColor: "#8ec5fc",
            fontSize: "0.9rem",
        };
    } else if (day.holidayType?.name === "Sarbatoare nationala") {
        return {
            color: "white",
            backgroundColor: "#099",
            fontSize: "0.9rem",
        };
    } else
        return {
            color: "#4b4e52",
            fontSize: "0.9rem",
        };
};


