import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../constants";
import { useContext } from "react";
import { TimesheetContext } from "../contexts/TimesheetContext";
import { DevLogsModel } from "../models/DevLogsModel";

const fetchLogs = async (token: string): Promise<DevLogsModel[]> => {
    const response = await fetch(`${baseURL}/api/Dev/GetLogs`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) throw new Error("Failed to fetch logs");
    return await response.json();
};

export const useGetLogs = () => {
    const { accessToken: token } = useContext(TimesheetContext);
    return useQuery<DevLogsModel[], Error>({
        queryKey: ["logs"],
        queryFn: () => fetchLogs(token),
        enabled: !!token,
    });
};
