import React from "react";
import { EmployeesList } from "../components/Admin/EmployeesList";

export const EmployeesPage = () => {
    return (
        <div>
            <EmployeesList />
        </div>
    );
};
