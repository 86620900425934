import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants"; // Adjust path if necessary
import { ParseDate } from "../../utils/dateUtils";

// API function to validate all
const managerValidateAll = async (
	begin: Date,
	end: Date,
	statusCode: number,
	token: string
): Promise<{ data: string; status: number }> => {
	const monday = ParseDate(begin);
	const friday = ParseDate(end);

	const response = await fetch(
		`${baseURL}/api/Manager/ValidateAll/${monday}/${friday}?statusCode=${statusCode}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error(`Validation failed with status: ${response.status}`);
	}

	const data = await response.text();
	return { data, status: response.status };
};

// Custom hook using useMutation
export const useManagerValidateAll = () => {
	return useMutation({
		mutationFn: ({
			begin,
			end,
			statusCode,
			token,
		}: {
			begin: Date;
			end: Date;
			statusCode: number;
			token: string;
		}) => managerValidateAll(begin, end, statusCode, token),
	});
};
