import { FormikProps } from "formik";
import {
    DatePicker,
    FormField,
    H5,
    Input,
    List,
    ListItem,
    ListItemElement,
    ListItemLeft,
    P,
    TimePicker,
} from "@tag/tag-components-react-v4";
import EmployeeModalStyles from "./EmployeeModal.module.scss";
import { useGetDepartments } from "../../api/useGetDepartments";
import { EmployeeFormFields, getMinMaxHours, isWeekend } from "../../utils/employeesPageUtils";
import { Divider } from "../commons/Divider";
import { WorkingHoursModel } from "../../models/WorkingHoursModel";
import dayjs from "dayjs";
import { ClockFilled } from "@tag/tag-icons";

export const WorkHoursForm = ({
    formik,
    workHoursHistory,
}: {
    formik: FormikProps<EmployeeFormFields>;
    workHoursHistory: WorkingHoursModel[];
}) => {
    const { data: departments } = useGetDepartments();
    const minMaxHours = getMinMaxHours(departments, formik.values.departmentId);

    return (
        <div className={EmployeeModalStyles.dateContainer}>
            <FormField
                label="Working Hours"
                editor={<Input {...formik.getFieldProps("workHours")} style={{ width: "100%" }} />}
                invalid={!!(formik.errors.workHours && formik.touched.workHours)}
                validationErrors={formik.errors.workHours}
            />
            <FormField
                label="Start Date"
                editor={
                    <DatePicker
                        {...formik.getFieldProps("startDate")}
                        style={{ width: "100%" }}
                        clearButton
                        onChange={(e) => {
                            formik.setFieldValue("startDate", e.utcDate);
                        }}
                        getDayProps={(date) => {
                            return { disabled: isWeekend(date) };
                        }}
                    />
                }
                invalid={!!(formik.errors.startDate && formik.touched.startDate)}
                validationErrors={formik.errors.startDate}
            />
            <FormField
                label="Schedule Begin"
                editor={
                    <TimePicker
                        {...formik.getFieldProps("scheduleBegin")}
                        style={{ width: "100%" }}
                        is24HourFormat
                        showSeconds={false}
                        hideNowButton
                        hideConfirmButton={false}
                        minHour={minMaxHours?.[0] ?? 0}
                        maxHour={minMaxHours?.[1] ?? 23}
                        disabled={!formik.values.departmentId}
                    />
                }
                invalid={!!(formik.errors.scheduleBegin && formik.touched.scheduleBegin)}
                validationErrors={formik.errors.scheduleBegin}
            />
            <FormField
                label="Schedule End"
                editor={
                    <TimePicker
                        {...formik.getFieldProps("scheduleEnd")}
                        style={{ width: "100%" }}
                        is24HourFormat
                        showSeconds={false}
                        hideNowButton
                        hideConfirmButton={false}
                        minHour={minMaxHours?.[0] ?? 0}
                        maxHour={minMaxHours?.[1] ?? 23}
                        disabled={!formik.values.departmentId}
                    />
                }
                invalid={!!(formik.errors.scheduleEnd && formik.touched.scheduleEnd)}
                validationErrors={formik.errors.scheduleEnd}
            />
            <div className={EmployeeModalStyles.fullWidthColumn}>
                <Divider content={"Work Hours History"} orientation={"center"} />
            </div>
            <List className={EmployeeModalStyles.fullWidthColumn}>
                {workHoursHistory.map((workHour) => (
                    <ListItem
                        style={{ display: "flex", justifyContent: "space-between", gap: "64px" }}
                    >
                        <ListItemElement icon={<ClockFilled />}>
                            <P>From: {dayjs(workHour.from).toDate().toLocaleDateString()}</P>
                        </ListItemElement>
                        <ListItemElement>
                            <P>Hours: {workHour.value}</P>
                        </ListItemElement>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};
