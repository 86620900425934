import {
    DatePicker,
    FormField,
    ModalForm,
    P,
    Switch,
    Toggle,
    useToasts,
} from "@tag/tag-components-react-v4";
import { useContext, useState } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { REQUIRED_VALIDATION_MESSAGE, TOAST_CONTAINER_ID } from "../../constants";
import { ProfileModel } from "../../models/ProfileModel";
import { useFormik } from "formik";
import { isWeekend, prepareFiredEmployeeValues } from "../../utils/employeesPageUtils";
import { useDeleteEmployeeAdmin } from "../../api/EmployeesApi/useDeleteEmployeeAdmin";
import { useAddUpdateEmployeeAdmin } from "../../api/EmployeesApi/useAddUpdateEmployeeAdmin";

interface DeleteFormFields {
    leaveDate: Date | null;
}
const setInitialValues = (): DeleteFormFields => {
    return { leaveDate: null };
};

export const DeleteEmployeeModal = ({
    employee,
    onClose,
}: {
    employee: ProfileModel;
    onClose: () => void;
}) => {
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const [isFired, setIsFired] = useState<boolean>(!!employee.leaveDate);
    const [leaveDate, setLeaveDate] = useState<Date | null>(
        employee.leaveDate ? new Date(employee.leaveDate) : null,
    );
    const { mutateAsync: onDeleteEmployee } = useDeleteEmployeeAdmin();
    const { mutate: onEditEmployee } = useAddUpdateEmployeeAdmin();
    const formik = useFormik({
        initialValues: setInitialValues(),
        validate: (values: DeleteFormFields): Partial<Record<keyof DeleteFormFields, string>> => {
            const errors: Partial<Record<keyof DeleteFormFields, string>> = {};
            if (isFired && !values.leaveDate) errors.leaveDate = REQUIRED_VALIDATION_MESSAGE;
            return errors;
        },
        onSubmit: (values) => {
            if (!isFired) {
                const preparedEmployeeValue = prepareFiredEmployeeValues(employee);
                onEditEmployee(
                    {
                        employee: { ...preparedEmployeeValue, leaveDate: null },
                        token: accessToken,
                        id: employee.id,
                    },
                    {
                        onError: (error) => {
                            toast(`Could not update leave date of employee : ${error}`, {
                                toastType: "error",
                            });
                        },
                        onSuccess: () => {
                            toast("Succesfully updated leave date", { toastType: "success" });
                        },
                    },
                );
            } else
                onDeleteEmployee({
                    id: employee.id,
                    token: accessToken,
                    leaveDate: values.leaveDate!,
                });
            onClose();
        },
    });
    const handleUnfire = () => {
        formik.setFieldValue("leaveDate", null);
    };
    return (
        <ModalForm
            visible={true}
            heading={`Fire Employee`}
            submitButtonProps={{ text: "Save", style: { padding: "0px 32px" } }}
            cancelButtonProps={{ text: "Cancel", style: { padding: "0px 32px" } }}
            onSubmit={formik.handleSubmit}
            onClose={onClose}
            headingProps={{
                style: { fontWeight: "bold" },
            }}
        >
            <P style={{ marginBottom: "1rem" }}>
                Are you sure you want to fire user: <strong>{employee.fullName}</strong>?
            </P>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    gap: "16px",
                }}
            >
                <FormField
                    label="Fired"
                    editor={
                        <Switch
                            checked={isFired}
                            onChange={(e) => {
                                handleUnfire();
                                setIsFired(e.target.value);
                            }}
                            accent="teal"
                        ></Switch>
                    }
                />
                <FormField
                    label="Leave Date"
                    editor={
                        <DatePicker
                            // {...formik.getFieldProps("leaveDate")}
                            value={leaveDate}
                            style={{ width: "100%" }}
                            clearButton
                            onChange={(e) => {
                                setLeaveDate(e.target.value);
                                formik.setFieldValue("leaveDate", e.utcDate);
                            }}
                            getDayProps={(date) => {
                                return { disabled: isWeekend(date) };
                            }}
                            disabled={!isFired}
                        />
                    }
                    invalid={!!(formik.errors.leaveDate && formik.touched.leaveDate)}
                    validationErrors={formik.errors.leaveDate}
                />
            </div>
        </ModalForm>
    );
};
