import { DataList, Label, P, Pager, Pill, Searchbox, Spinner } from "@tag/tag-components-react-v4";
import React, { useContext, useState } from "react";
import { filterHolidays, getHolidayPages, sortManagerHolidays } from "../../utils/managerUtils";
import { useManagerHolidays } from "../../api/ManagersApi/useManagerGetHoliday";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { ValidationLeavesItem } from "./ValidationLeavesItem";
import { ErrorCard } from "../commons/ErrorCard";

export type ManagersValidationLeavesProps = {};

export const ManagersValidationLeaves = (props: ManagersValidationLeavesProps) => {
	const { accessToken } = useContext(TimesheetContext);
	const [searchCriteria, setSearchCriteria] = useState<string>("");
	const [page, setPage] = useState<number>(1);
	const pageSize = 5;
	const pageSkip = (page - 1) * pageSize;

	const {
        data: {totalCount, managerHolidayData} = {},
        isLoading,
        error,
    } = useManagerHolidays(accessToken, pageSize, pageSkip);
	const sortedManagerHolidayData = managerHolidayData?.length
		? sortManagerHolidays(managerHolidayData)
		: [];

    const handleSearch = (e: any) => {
        setSearchCriteria(e.target.value);
        setPage(1);
    };
    const filteredHolidays = filterHolidays(sortedManagerHolidayData, searchCriteria);
    const holidaysCount = filteredHolidays?.length;
    const holidaysPageCount = holidaysCount && Math.ceil(holidaysCount / pageSize);
    const holidaysPageData = getHolidayPages(filteredHolidays, page, pageSize);
    return (
        <div style={{ height: "25rem" }}>
            <div style={{ marginTop: "2%", display: "flex", alignItems: "center", gap: "16px" }}>
                <Searchbox
                    placeholder="Search by Name, Email or Marca..."
                    onChange={handleSearch}
                    clearButton
                    value={searchCriteria}
                    style={{ width: "300px" }}
                />
                <Pill
                    variant="outlined"
                    accent="teal"
                    size="large"
                    style={{
                        height: "35px",
                        display: "flex",
                        fontSize: "15px",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <Label>Records: </Label>
                    <P style={{ fontSize: "15px" }}>{holidaysCount}</P>
                </Pill>
            </div>
            {isLoading && <Spinner>Loading... </Spinner>}
            {error && <ErrorCard error={error} />}
            <DataList
                style={{ marginTop: "1%", height: "300px" }}
                data={holidaysPageData}
                renderItem={(item) => (
                    <ValidationLeavesItem holiday={item} page={page} setPage={setPage} />
                )}
            ></DataList>
            <Pager
                page={page}
                pageCount={holidaysPageCount}
                accent="teal"
                showFirstLast
                onPageRequest={(e) => setPage(e.page)}
            />
        </div>
    );
};
