import { H5 } from "@tag/tag-components-react-v4";
import React from "react";
export type DividerProps = {
	content: string;
	orientation: "center" | "left" | "right";
};
export const Divider = (props: DividerProps) => {
	const { content, orientation } = props;
	const getLineStyle = (side: "left" | "right") => {
		const flexValues = {
			center: 1,
			left: side === "left" ? 0.1 : 1,
			right: side === "right" ? 0.1 : 1,
		};
		return {
			flex: flexValues[orientation],
			borderBottom: "2px solid lightgray",
			margin: "0 10px",
		};
	};

	const shortLineStyle = {
		borderBottom: "2px solid lightgray",
		width: "20px",
		margin: "0 5px",
	};

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				textAlign: "center",
				width: "100%",
				marginTop: "1%",
			}}
		>
			{/* Left Divider Line */}
			<div style={getLineStyle("left")}></div>

			{/* Content */}
			<H5 style={{ whiteSpace: "nowrap" }}>{content}</H5>

			{/* Right Divider Line */}
			<div style={getLineStyle("right")}></div>
		</div>
	);
};
