import { useMutation, useQueryClient } from "@tanstack/react-query";
import { baseURL, ERROR_COULDNT_ADDUPDATE_EMPLOYEE_DETAILS } from "../../constants";
import { EmployeeModel } from "../../models/EmployeeModel";
const addUpdateEmployeeAdmin = async (
    timesheet: EmployeeModel,
    token: string,
    id: number,
): Promise<any> => {
    const response = await fetch(`${baseURL}/api/Admin/AddOrUpdateEmployee/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(timesheet),
    });

    if (!response.ok) throw new Error(ERROR_COULDNT_ADDUPDATE_EMPLOYEE_DETAILS);
    return response.status;
};

export const useAddUpdateEmployeeAdmin = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["addUpdateEmployeeAdmin"],
        mutationFn: ({
            employee,
            token,
            id,
        }: {
            employee: EmployeeModel;
            token: string;
            id: number;
        }) => addUpdateEmployeeAdmin(employee, token, id),
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
};
