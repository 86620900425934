import { useMutation, useQueryClient } from "@tanstack/react-query";
import { baseURL, SUCCESS_ADDUPDATE_DAY_OFF, TOAST_CONTAINER_ID } from "../constants"; 
import { HolidayModel } from "../models/HolidayModel"; 
import { useToasts } from "@tag/tag-components-react-v4";
const addUpdateHoliday = async (holiday: HolidayModel, token: string): Promise<any> => {
    const response = await fetch(`${baseURL}/api/Holiday/AddOrUpdate`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(holiday),
    });

    if (!response.ok) throw new Error("Failed to add or update holiday.");
    return await response.json();
};

export const useAddUpdateHoliday = () => {
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);

    return useMutation({
        mutationKey: ["addUpdateHoliday"],
        mutationFn: ({ holiday, token }: { holiday: HolidayModel; token: string }) =>
            addUpdateHoliday(holiday, token),
        onSuccess: () => {
            queryClient.invalidateQueries();
            toast(SUCCESS_ADDUPDATE_DAY_OFF, { toastType: "success" });
        },
    });
};
