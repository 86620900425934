import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    baseURL,
    ERROR_COULDNT_ADDUPDATE_WORKTYPE,
    SUCCESS_ADDUPDATE_WORKTYPE,
    TOAST_CONTAINER_ID,
} from "../../constants";
import { useToasts } from "@tag/tag-components-react-v4";
import dayjs from "dayjs";
const addUpdateWorkType = async (date: Date, workTypeId: number, token: string): Promise<any> => {
    const requestBody = {
        date: date,
        workTypeId: `${workTypeId}`,
    };
    const response = await fetch(`${baseURL}/api/Timesheet/AddOrUpdateWorkLocation`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
    });

    if (!response.ok)
        throw new Error(
            ERROR_COULDNT_ADDUPDATE_WORKTYPE + ` (${dayjs(date).format("MM/DD/YYYY")})`,
        );
    return await response.status;
};

export const useAddUpdateWorkType = () => {
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);

    return useMutation({
        mutationKey: ["addUpdateWorkType"],
        mutationFn: ({
            date,
            workTypeId,
            token,
        }: {
            date: Date;
            workTypeId: number;
            token: string;
        }) => addUpdateWorkType(date, workTypeId, token),
        onSuccess: (data, { date }) => {
            queryClient.invalidateQueries();
            toast(SUCCESS_ADDUPDATE_WORKTYPE + ` (${dayjs(date).format("MM/DD/YYYY")})`, {
                toastType: "success",
            });
        },
    });
};
