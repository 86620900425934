import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { TimesheetContextProvider } from "./contexts/TimesheetContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { MsalInitializer } from "./MsalInitializer";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);



root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <MsalInitializer>
                <BrowserRouter>
                    <TimesheetContextProvider>
                        <App />
                    </TimesheetContextProvider>
                </BrowserRouter>
            </MsalInitializer>
        </QueryClientProvider>
    </React.StrictMode>,
);
// This will only run after MSAL initialization completes

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
