import {
    Autocomplete,
    DateRangePicker,
    Label,
    SimpleButton,
    useToasts,
} from "@tag/tag-components-react-v4";
import { HourglassEmptyFilled, SaveDiskFilled } from "@tag/tag-icons";
import React, { useContext, useState } from "react";
import { ManagerStatsModel } from "../../models/ManagerStatsModel";
import { getEmployeesForSearch } from "../../utils/managerUtils";
import { useAddHolidayManager } from "../../api/ManagersApi/useManagerAddUpdateHoliday";
import { TOAST_CONTAINER_ID } from "../../constants";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { useQueryClient } from "@tanstack/react-query";
export type ManagerAddHolidayFormProps = {
    statsForManager: ManagerStatsModel[] | undefined;
};
export const ManagerAddHolidayForm = (props: ManagerAddHolidayFormProps) => {
    const { statsForManager } = props;
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const queryClient = useQueryClient();
    const { accessToken } = useContext(TimesheetContext);
    const [employeeEmail, setEmployeeEmail] = useState("");
    const [holidayObject, setHolidayObject] = useState<any>({
        type: null,
        typeId: null,
        begin: new Date(),
        end: new Date(),
    });

    const employeesForSearch = statsForManager ? getEmployeesForSearch(statsForManager) : [];

    const { mutate: addHoliday, isPending: isAddingHolidayPending } = useAddHolidayManager();
    const handleSave = () => {
        if (!employeeEmail || !holidayObject.typeId || !holidayObject.begin || !holidayObject.end) {
            toast("Please fill all the required fields.", { toastType: "error" });
            return;
        }

        addHoliday(
            { holiday: holidayObject, email: employeeEmail, token: accessToken },
            {
                onError: (error: any) => {
                    toast(`Failed to add or edit the holiday: ${error.message}`, {
                        toastType: "error",
                    });
                },
                onSuccess: () => {
                    toast("Holiday updated successfully!", { toastType: "success" });
                    queryClient.invalidateQueries();
                    setEmployeeEmail("");
                    setHolidayObject({
                        type: null,
                        typeId: null,
                        begin: new Date(),
                        end: new Date(),
                    });
                },
            },
        );
    };

    return (
        <div
            style={{
                display: "flex",
                gap: "16px",
                flexDirection: "column",
                alignItems: "flexStart",
                marginLeft: "1%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                    marginTop: "3%",
                }}
            >
                <Label>Employee:</Label>
                <Autocomplete
                    valueField="email"
                    textField={"fullName"}
                    value={employeeEmail}
                    data={employeesForSearch}
                    placeholder="Enter Employee Name"
                    clearButton
                    onChange={(e) => setEmployeeEmail(e.target.value)}
                    style={{ width: "320px" }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                }}
            >
                <Label>Select working dates:</Label>
                <DateRangePicker
                    pickerType="buttons"
                    value={[holidayObject.begin, holidayObject.end]}
                    onChange={(e) =>
                        setHolidayObject({
                            ...holidayObject,
                            begin: e.target.value[0],
                            end: e.target.value[1],
                        })
                    }
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                }}
            >
                <Label>Others:</Label>
                <Autocomplete
                    placeholder="Choose leave type"
                    data={[
                        { id: 1, name: "CO – Annual Leave" },
                        { id: 3, name: "CPL – Special Leave (RO)" },
                        { id: 8, name: "D – Delegation" },
                        { id: 9, name: "BB – Big Break" },
                    ]}
                    value={holidayObject.typeId}
                    clearButton
                    valueField="id"
                    textField="name"
                    style={{ width: "340px" }}
                    onChange={(e) =>
                        setHolidayObject({
                            ...holidayObject,
                            typeId: e.target.value,
                            type: null,
                        })
                    }
                />
            </div>
            <SimpleButton
                style={{ width: "50%" }}
                startIcon={isAddingHolidayPending ? <HourglassEmptyFilled /> : <SaveDiskFilled />}
                accent="teal"
                disabled={!employeeEmail || !holidayObject.typeId || isAddingHolidayPending}
                onClick={handleSave}
            >
                Save
            </SimpleButton>
        </div>
    );
};
