import { Modal, P, useToasts } from "@tag/tag-components-react-v4";
import { useDeleteHoliday } from "../../api/useDeleteHoliday";
import { useContext } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { HolidayModel } from "../../models/HolidayModel";
import { ERROR_COULDNT_DELETE_DAY_OFF, TOAST_CONTAINER_ID } from "../../constants";
import dayjs from "dayjs";

export const DeleteDayOffModal = ({
    holiday,
    onClose,
}: {
    holiday: HolidayModel;
    onClose: () => void;
}) => {
    const { mutateAsync: onDeleteHoliday } = useDeleteHoliday();
    const handleClick = () => {
        if (holiday.id)
            onDeleteHoliday({ id: holiday.id, token: accessToken }).catch(() =>
                toast(ERROR_COULDNT_DELETE_DAY_OFF, { toastType: "error" }),
            );
        else toast(ERROR_COULDNT_DELETE_DAY_OFF, { toastType: "warning" });
    };
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    return (
        <Modal
            visible={true}
            accent="teal"
            heading="Delete Day Off"
            onPrimaryButtonClick={() => {
                handleClick();
                onClose();
            }}
            onCancelButtonClick={() => onClose()}
            suppressCloseButton={true}
        >
            <P>
                Are you sure u want to delete this Day Off?{" "}
                {`(${dayjs(holiday?.begin).format("DD/MM/YYYY")} -
          ${dayjs(holiday?.end).format("DD/MM/YYYY")})`}
            </P>
        </Modal>
    );
};
