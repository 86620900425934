import {
    LeftNavigation,
    LeftNavigationBody,
    LeftNavigationHeader,
    LeftNavigationItem,
    LeftNavigationItemContent,
    LeftNavigationPanel,
    useNavigationItemProps,
} from "@tag/tag-components-react-v4";
import {
    CircleUserFilled,
    CalendarBlankFilled,
    WheeledSuitcaseFilled,
    Presentation2Filled,
    UsersFilled,
    BarChart2Filled,
    ToolsFilled,
    ExitFilled,
    Calendar2Lined,
    RealTimeLined,
} from "@tag/tag-icons";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { useMsal } from "@azure/msal-react";
type CustomLinkProps = React.ComponentPropsWithoutRef<typeof Link> & { selected?: boolean };

const CustomLink = React.forwardRef<HTMLAnchorElement, CustomLinkProps>(function CustomLink(
    { selected, onKeyDown, ...nativeProps },
    ref,
) {
    const navigateProps = useNavigationItemProps({ selected, ref, onKeyDown });

    return <Link {...navigateProps} {...nativeProps} />;
});
export const SideMenu = () => {
    const location = useLocation();
    const { instance } = useMsal();
    const { profile } = useContext(TimesheetContext);
    const handleMenuItemChange = (item?: string) => {
        if (item === "/logout") {
            instance.logout();
        }
    };
    return (
        <LeftNavigation
            onSelectedItemChange={handleMenuItemChange}
            selectedItem={location.pathname}
            style={{ position: "sticky", top: 0, height: "100vh" }}
        >
            <LeftNavigationHeader>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "10rem",
                    }}
                >
                    <span style={{ marginLeft: "4px" }}>Access Ro TimeSheet</span>
                </div>
            </LeftNavigationHeader>
            <LeftNavigationBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-between",
                }}
            >
                <LeftNavigationPanel>
                    <LeftNavigationItem name="/timesheet">
                        <CustomLink
                            to="timesheet"
                            style={{ textDecoration: "none", width: "100%", height: "100%" }}
                        >
                            <LeftNavigationItemContent
                                name="/timesheet"
                                icon={<CalendarBlankFilled />}
                                text="Timesheet"
                            />
                        </CustomLink>
                    </LeftNavigationItem>

                    <LeftNavigationItem name="/profile">
                        <CustomLink to="profile" style={{ textDecoration: "none", width: "100%" }}>
                            <LeftNavigationItemContent
                                name="/profile"
                                icon={<CircleUserFilled />}
                                text="Profile"
                            />
                        </CustomLink>
                    </LeftNavigationItem>
                    <LeftNavigationItem name="/leaves">
                        <CustomLink to="leaves" style={{ textDecoration: "none", width: "100%" }}>
                            <LeftNavigationItemContent
                                name="/leaves"
                                icon={<WheeledSuitcaseFilled />}
                                text="Leaves"
                            />
                        </CustomLink>
                    </LeftNavigationItem>

                    {profile?.roles.includes("Manager") && (
                        <LeftNavigationItem name="/managers">
                            <CustomLink
                                to="managers"
                                style={{ textDecoration: "none", width: "100%" }}
                            >
                                <LeftNavigationItemContent
                                    name="/managers"
                                    icon={<Presentation2Filled />}
                                    text="Managers"
                                />
                            </CustomLink>
                        </LeftNavigationItem>
                    )}

                    {profile?.roles.includes("Admin") && (
                        <LeftNavigationItem name="/employees">
                            <CustomLink
                                to="employees"
                                style={{ textDecoration: "none", width: "100%" }}
                            >
                                <LeftNavigationItemContent
                                    name="/employees"
                                    icon={<UsersFilled />}
                                    text="Employees"
                                />
                            </CustomLink>
                        </LeftNavigationItem>
                    )}
                    {profile?.roles.includes("Admin") && (
                        <LeftNavigationItem name="/IOReport">
                            <CustomLink
                                to="IOReport"
                                style={{ textDecoration: "none", width: "100%" }}
                            >
                                <LeftNavigationItemContent
                                    name="/IOReport"
                                    icon={<Calendar2Lined />}
                                    text="IO Report"
                                />
                            </CustomLink>
                        </LeftNavigationItem>
                    )}

                    {profile?.roles.includes("Admin") && (
                        <LeftNavigationItem name="/RDReport">
                            <CustomLink
                                to="RDReport"
                                style={{ textDecoration: "none", width: "100%" }}
                            >
                                <LeftNavigationItemContent
                                    name="/RDReport"
                                    icon={<RealTimeLined />}
                                    text="R&D Report"
                                />
                            </CustomLink>
                        </LeftNavigationItem>
                    )}

                    {profile?.roles.includes("Admin") && (
                        <LeftNavigationItem name="/statistics">
                            <CustomLink
                                to="statistics"
                                style={{ textDecoration: "none", width: "100%" }}
                            >
                                <LeftNavigationItemContent
                                    name="/statistics"
                                    icon={<BarChart2Filled />}
                                    text="Statistics"
                                />
                            </CustomLink>
                        </LeftNavigationItem>
                    )}

                    {profile?.roles.includes("Developer") && (
                        <LeftNavigationItem name="/logs">
                            <CustomLink to="logs" style={{ textDecoration: "none", width: "100%" }}>
                                <LeftNavigationItemContent
                                    name="/logs"
                                    icon={<ToolsFilled />}
                                    text="Developers"
                                />
                            </CustomLink>
                        </LeftNavigationItem>
                    )}
                </LeftNavigationPanel>
                <LeftNavigationPanel>
                    <LeftNavigationItem name="/logout">
                        <LeftNavigationItemContent
                            name="/logout"
                            icon={<ExitFilled />}
                            text="Logout"
                        />
                    </LeftNavigationItem>
                </LeftNavigationPanel>
            </LeftNavigationBody>
        </LeftNavigation>
    );
};
